import { Route } from "@/paths";
import { useContactDeals } from "@/resources/contacts";
import { Card, Group, Stack, Text } from "@mantine/core";
import { HiExternalLink } from "react-icons/hi";
import { Link, generatePath } from "react-router-dom";

export const DealList = () => {
  const [deals, loading] = useContactDeals();

  return (
    <Stack gap={"xs"}>
      <Text size="sm" fw={600}>
        Linked Deals
      </Text>
      <Stack gap={"xs"}>
        {loading && <Text>Loading...</Text>}
        {!loading && (deals || [])?.length === 0 && (
          <Text size="sm" c="dimmed">
            No deals attached to this contact
          </Text>
        )}
        {deals?.map((deal) => (
          <Card
            withBorder
            key={deal.id}
            component={Link}
            to={generatePath(Route.Deal, { dealId: deal.id })}
          >
            <Group>
              <Text size="sm" fw={500}>
                {deal.companyName || "Untitled Deal"}
              </Text>
              <Text c="dimmed" size="xs">
                {deal.createdAt.toDate().toLocaleDateString()}
              </Text>

              <HiExternalLink color="gray" style={{ marginLeft: "auto" }} />
            </Group>
          </Card>
        ))}
      </Stack>
    </Stack>
  );
};
