import { Modal, Text } from "@mantine/core";

export const ModelLoadError = () => {
  return (
    <Modal
      opened={true}
      title={
        <Text size="lg" fw={"bold"}>
          Model Not Found
        </Text>
      }
      closeOnEscape={false}
      onClose={() => {}}
      withCloseButton={false}
    >
      <Text>
        The model you are looking for cannot be found. You may have the wrong
        url, it might have been deleted, or you may not have permission to
        access to it.
      </Text>
    </Modal>
  );
};
