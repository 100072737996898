import { Route } from "@/paths";
import { ActionIcon, Group, Tabs } from "@mantine/core";
import { FaSlack } from "react-icons/fa";
import { FaCircleUser } from "react-icons/fa6";
import { Link, generatePath, useLocation, useNavigate } from "react-router-dom";
import { css } from "vite-plugin-inline-css-modules";
import { LayoutWorkstation } from "../LayoutWorkstation/LayoutWorkStation";
import { Logo } from "../Logo/Logo";

const styles = css`
  .tab {
    height: 42px;
    &[data-active] {
      border-radius: 0;
      background-color: var(--mantine-color-accent-0);
      border-bottom: 4px solid var(--mantine-color-accent-3);
    }
  }
`;

const tabsData = [
  {
    id: Route.DealTracker,
    label: "Deal Tracker",
  },
  {
    id: Route.Contacts,
    label: "Contacts",
  },
  {
    id: Route.Models,
    label: "Models",
  },
] as const;

export const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentTab = tabsData.find((tab) => tab.id === location.pathname);

  return (
    <LayoutWorkstation.Header px={"xs"}>
      <Logo w={56} variant="dark" to={Route.DealTracker} />

      <Tabs
        classNames={{ tab: styles.tab }}
        color="infomatic.3"
        keepMounted={false}
        value={currentTab?.id}
        onChange={(id) => navigate(generatePath(id!))}
      >
        <Tabs.List>
          {tabsData.map((tab) => (
            <Tabs.Tab
              key={tab.id}
              value={tab.id}
              fw={600}
              px={{
                sm: "xs",
                md: 8,
                lg: "lg",
              }}
              w={120}
            >
              {tab.label}
            </Tabs.Tab>
          ))}
        </Tabs.List>
      </Tabs>

      <Group ml={"auto"} gap={"xs"} align="center">
        <ActionIcon
          h={30}
          variant="transparent"
          size={"md"}
          component={Link}
          to={
            "https://join.slack.com/t/eta-iq/shared_invite/zt-2vslda02d-wXBjD7Z3xE33E9okMF0zMQ"
          }
          target="_blank"
        >
          <FaSlack size={22} />
        </ActionIcon>
        <ActionIcon
          h={30}
          variant="transparent"
          radius={999}
          size={"md"}
          component={Link}
          to={Route.Profile}
          target="_blank"
        >
          <FaCircleUser size={24} />
        </ActionIcon>
      </Group>
    </LayoutWorkstation.Header>
  );
};
