import { BiPencil, BiTrash } from "react-icons/bi";
import { FaCopy, FaRegEye } from "react-icons/fa";
import { IoLink } from "react-icons/io5";
import { MdOutlineClose } from "react-icons/md";
import { RiShareForward2Fill } from "react-icons/ri";

export const Icons = {
  Delete: BiTrash,
  Edit: BiPencil,
  Remove: MdOutlineClose,
  Clone: FaCopy,
  View: FaRegEye,
  Share: RiShareForward2Fill,
  Link: IoLink,
};
