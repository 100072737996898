import { ZIndexOrder } from "@/theme";
import { LoadingOverlay } from "@mantine/core";

export const LoadingSpinner = () => {
  return (
    <LoadingOverlay
      visible={true}
      zIndex={ZIndexOrder.ModelOnPageLoaderSpinner}
      overlayProps={{ radius: "sm", blur: 2 }}
    />
  );
};
