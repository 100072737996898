import { useIsPlanInactive } from "@/hooks/useIsPlanInactive";
import { useQuery, useQuerySharedWithMe } from "@/hooks/useModels";
import { Box, Card, Group, SegmentedControl, Stack } from "@mantine/core";
import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { AppGrid, appGridApiAtom } from "../AppGrid/AppGrid";
import { AppGridSearch } from "../AppGrid/AppGridSearch/AppGridSearch";
import { CreateModelButton } from "./CreateModelButton";
import { colDefs, defautColDefs } from "./colDefs";

export const PageModels = () => {
  const { inactiveElementProps, isPlanInactive } = useIsPlanInactive();
  const [tabValue, setTabValue] = useState("my-models");
  const myModelsQuery = useQuery();
  const sharedWithMyQuery = useQuerySharedWithMe();
  const gridApi = useAtomValue(appGridApiAtom);
  const query =
    tabValue === "shared-with-me" ? sharedWithMyQuery : myModelsQuery;

  useEffect(() => {
    if (!gridApi) return;

    gridApi
      ?.setColumnFilterModel(
        "isShared",
        tabValue === "my-models"
          ? null
          : {
              filterType: "boolean",
              type: "equals",
              filter: true,
            },
      )
      ?.then(() => {
        gridApi?.onFilterChanged();
      });

    setTimeout(() => {
      gridApi?.setColumnsVisible(["owner"], tabValue === "shared-with-me");
    }, 1);

    gridApi?.setColumnsVisible(
      ["deal", "actions", "owner"],
      tabValue !== "shared-with-me",
    );
  }, [tabValue, gridApi]);

  return (
    <Stack w={"100%"} h={"100%"} p={"sm"}>
      <Group w={"100%"} justify="space-between" align="center">
        {" "}
        <Box w={200}>
          <CreateModelButton disabled={isPlanInactive} />
        </Box>
        <SegmentedControl
          color="blue"
          value={tabValue}
          onChange={setTabValue}
          data={[
            { label: "My Models", value: "my-models" },
            { label: "Shared To Others", value: "shared-to-others" },
            { label: "Shared With Me", value: "shared-with-me" },
          ]}
          styles={{
            control: { borderRadius: 0, width: 160 },
          }}
        />
        <Box w={200}>
          <AppGridSearch label={undefined} size="sm" />
        </Box>
      </Group>
      <Card
        radius={0}
        p={0}
        flex={1}
        display={"grid"}
        style={{
          gridTemplateRows: `1fr`,
          gridTemplateColumns: `1fr`,
          overflow: "hidden",
          ...inactiveElementProps.style,
        }}
      >
        <AppGrid
          id="models"
          blockStateSaving
          columnDefs={colDefs}
          query={query}
          suppressCellFocus={true}
          defaultColDef={defautColDefs}
          localeText={{
            noRowsToShow: `No models to show.`,
          }}
        />
      </Card>
    </Stack>
  );
};
