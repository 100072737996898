import { coreModelAtom, modelAtom } from "@/financeModels/owasco/model";
import { isModelReadOnlyFromHookAtom } from "@/hooks/useModel";
import { collectionRef } from "@/hooks/useModels";
import { Route } from "@/paths";
import { getShortId } from "@/utils/id";
import {
  Timestamp,
  addDoc,
  collection,
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import { atom } from "jotai";
import { atomEffect } from "jotai-effect";
import { matchPath } from "react-router-dom";
import { PersistableModel } from "./types";

const saveRevisionInterval = 40;
let revisionSaveCounter = 0;

const getModelId = () => {
  return matchPath({ path: Route.Model, end: false }, location.pathname)?.params
    .modelId;
};

export const lastSaveAttemptIdAtom = atom("");
export const lastSaveCompletedIdAtom = atom("");
export const lastSaveErrorIdAtom = atom("");

export const isSavingAtom = atom((get) => {
  return get(lastSaveAttemptIdAtom) !== get(lastSaveCompletedIdAtom);
});

export const isSaveErrorAtom = atom((get) => {
  return get(lastSaveAttemptIdAtom) === get(lastSaveErrorIdAtom);
});

let hasSetLoadedModel = false;
const loadedModelFromFirebaseAtom = atom<PersistableModel | null>(null);
loadedModelFromFirebaseAtom.onMount = (setAtom) => {
  hasSetLoadedModel = false;
  const docRef = doc(collectionRef, getModelId(), "revision", "latest");
  getDoc(docRef)
    .then((doc) => {
      const data = doc.data();
      setAtom(data as PersistableModel);
    })
    .catch(() => {
      // alert(
      //   "Error loading model. You may not have access to this model, it may have been deleted, or it may not exist.",
      // );
    });
};

export const saveToFirebaseEffect = atomEffect((get, set) => {
  const model = get(coreModelAtom);
  const modelId = getModelId();
  const modelRef = doc(collectionRef, modelId);
  const modelDocRef = doc(modelRef, "revision", "latest");

  // Load the model from firebase on mount
  if (get(loadedModelFromFirebaseAtom) === null) return;
  if (!hasSetLoadedModel) {
    set(modelAtom, get(loadedModelFromFirebaseAtom) || model);
    hasSetLoadedModel = true;
    return;
  }

  const saveActionId = getShortId();
  set(lastSaveAttemptIdAtom, saveActionId);

  if (get(isModelReadOnlyFromHookAtom)) return;

  //console.log("Saving model to firebase...", model);
  setDoc(modelDocRef, { ...model, createdAt: serverTimestamp() })
    .then(() => {
      set(lastSaveCompletedIdAtom, saveActionId);
      revisionSaveCounter++;

      if (revisionSaveCounter % saveRevisionInterval === 0) {
        addDoc(collection(modelRef, "revision"), {
          ...model,
          createdAt: serverTimestamp(),
        });
      }
    })
    .catch((e) => {
      console.log("Error saving model to firebase", e);
      set(lastSaveErrorIdAtom, saveActionId);
    });

  setDoc(
    modelRef,
    {
      updatedAt: serverTimestamp() as Timestamp,
      excerpt: {
        targetPurchasePrice: model?.general?.targetPurchasePrice || null,
        cashFlow: model?.general?.cashFlow || null,
      },
    },
    { merge: true },
  );
});

export const hasFinishedLoadingModelOnMountAtom = atom((get) => {
  return get(loadedModelFromFirebaseAtom) !== null;
});
