import { auth } from "@/firebase";
import { BaseResource, buildResource } from "@/resources/_resource";
import { Timestamp } from "firebase/firestore";
import { getDealDirectoryEntry } from "./dealDirectories";
import { Note } from "./notes";

export type Deal = BaseResource & {
  id: string;
  dealTrackerId: string;
  establishedAt: Timestamp | null;
  companyName: string;
  location: string;
  listingLink: string;
  brokerage: string;
  broker: string;
  categoryId: string[] | null;
  ndaCompleted: boolean;
  cimCompleted: boolean;
  purchasePrice: number | null;
  revenue: number | null;
  cashFlow: number | null;
  modelIds: string[] | null;
  pinnedModelId: string | null;
  remarks: string;
  industry: string;
  notes: Note[] | null;
  includesRealEstate: boolean;
  contactIds: string[] | null;
};

const resource = buildResource<Deal>({
  collectionPath: "deal",
  name: "Deal",
  getDefaultItem: () => {
    const catId = getDealDirectoryEntry()?.getItemCache()?.categories[0].id;

    return {
      createdAt: Timestamp.now(),
      establishedAt: null,
      dealTrackerId: auth?.currentUser?.uid || "UNKNOWN",
      status: "published",
      companyName: "Untitled Deal " + new Date().toLocaleDateString(),
      location: "",
      listingLink: "",
      brokerage: "",
      broker: "",
      categoryId: catId ? [catId] : null,
      ndaCompleted: false,
      cimCompleted: false,
      purchasePrice: 0,
      revenue: 0,
      cashFlow: 0,
      modelIds: null,
      pinnedModelId: null,
      remarks: "",
      industry: "",
      notes: null,
      includesRealEstate: false,
      contactIds: null,
    };
  },
});

const getDealId = () => {
  return window.location.pathname.split("/deal/")[1].split("/")[0];
};

export const useDealEntry = () => {
  return resource.entry(getDealId());
};

export default resource;
