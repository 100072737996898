import {
  createEpochAtom,
  deleteEpochAtom,
  orderedEpochsMolecule,
} from "@/financeModels/owasco/epoch";
import { dscrMolecule } from "@/financeModels/owasco/lookback";
import { Epoch } from "@/financeModels/owasco/types";
import { useIsModelReadOnly } from "@/hooks/useModel";
import { ZIndexOrder } from "@/theme";
import {
  ActionIcon,
  BoxComponentProps,
  Button,
  Group,
  Menu,
  NumberInput,
  Stack,
  Text,
} from "@mantine/core";
import { WritableAtom, useAtom, useAtomValue, useSetAtom } from "jotai";
import { memo, useEffect, useState } from "react";
import { BiSolidMessageSquareAdd } from "react-icons/bi";
import { PiPencil } from "react-icons/pi";
import { TbTrash } from "react-icons/tb";
import { css } from "vite-plugin-inline-css-modules";
import { Column, defaultWidth } from "../CellGrid/Column";
import { DSCRCell } from "../CellGrid/DSCRCell";
import { defaultLabelColumnWidth } from "../CellGrid/LabelColumn";
import { Row } from "../CellGrid/Row";
import { lineSize } from "../CellGrid/Waterfall";
import { defaultHeight } from "../CellGrid/cell/Wrapper";

const classes = css`
  .showIconOnHover {
    cursor: pointer;

    svg {
      opacity: 0;
    }

    &:hover {
      svg {
        opacity: 1;
      }
    }
  }
`;

export const EpochRow = ({ ...props }: BoxComponentProps) => {
  const epochAtoms = useAtomValue(orderedEpochsMolecule);
  const dscrAtoms = useAtomValue(dscrMolecule);
  const createEpoch = useSetAtom(createEpochAtom);
  const [isModelReadOnly] = useIsModelReadOnly();

  return (
    <Row
      {...props}
      style={{
        position: "sticky",
        top: 0,
        zIndex: ZIndexOrder.CellGridEpochRow,
        borderBottom: "1px solid #ddd",
      }}
      pl={defaultLabelColumnWidth + lineSize}
      bg={"gray.3"}
    >
      {epochAtoms.map((atom, i) => (
        <Column key={`${atom}`}>
          <DSCRCell
            dscrAtom={dscrAtoms[i]}
            docPath="@/docs/pages/model/historicals/dscr.mdx"
          >
            <CellContent atom={atom} />
          </DSCRCell>
        </Column>
      ))}

      {!isModelReadOnly && (
        <Button
          leftSection={<BiSolidMessageSquareAdd size={22} />}
          h={defaultHeight}
          w={defaultWidth}
          onClick={() => createEpoch()}
          justify="left"
          mx="sm"
          //variant="default"
        >
          Add Year
        </Button>
      )}
    </Row>
  );
};

// For some reason my linter isnt happy moving this into the Cell props?
// So its here....
type CellProps = {
  atom: WritableAtom<Epoch, [Epoch], void>;
};

export const CellContent = memo(
  ({ atom, ...props }: BoxComponentProps & CellProps) => {
    const [isModelShared] = useIsModelReadOnly();
    const [epoch, setEpoch] = useAtom(atom);

    return (
      <EditMenu epoch={epoch} onChange={setEpoch}>
        <Group
          className={classes.showIconOnHover}
          gap={0}
          justify="center"
          align="flex-start"
          {...props}
        >
          <Text fz={"md"} fw={500}>
            {epoch.year}
          </Text>
          {!isModelShared && <PiPencil color="#999" />}
        </Group>
      </EditMenu>
    );
  },
);

const EditMenu = ({
  epoch,
  onChange,
  children,
}: {
  epoch: Epoch;
  onChange: (epoch: Epoch) => void;
  children: React.ReactNode;
}) => {
  const [opened, setOpened] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [yearInputValue, setYearInputValue] = useState(epoch.year);
  const deleteEpoch = useSetAtom(deleteEpochAtom);

  useEffect(() => {
    setYearInputValue(epoch.year);
  }, [epoch.year]);

  const onSubmit = (e?: { preventDefault: () => void }) => {
    e?.preventDefault();
    onChange({ ...epoch, year: yearInputValue });
    setOpened(false);
  };

  const onMenuChange = () => {
    setOpened(!opened);
    setShowConfirmDelete(false);

    if (opened) {
      onSubmit();
    } else {
      setYearInputValue(epoch.year);
    }
  };

  const onEpochDelete = () => {
    setOpened(!opened);
    deleteEpoch(epoch.id);
  };

  return (
    <Menu
      width={defaultWidth}
      shadow="md"
      withArrow
      position="bottom"
      opened={opened}
      onChange={onMenuChange}
    >
      <Menu.Target>{children}</Menu.Target>

      <Menu.Dropdown>
        {!showConfirmDelete && (
          <Stack p="xs">
            <Group justify="space-between">
              <Text>Edit Year</Text>
              <ActionIcon
                color="red.8"
                size="sm"
                onClick={() => {
                  setShowConfirmDelete(true);
                }}
              >
                <TbTrash />
              </ActionIcon>
            </Group>
            <form onSubmit={onSubmit}>
              <Stack>
                <NumberInput
                  value={yearInputValue}
                  onChange={(value) => setYearInputValue(Number(value))}
                />
                <Button type="submit">Update</Button>
              </Stack>
            </form>
          </Stack>
        )}
        {showConfirmDelete && (
          <Stack p="xs">
            <Text ta="center">Are you sure? This can't be undone.</Text>
            <Button onClick={onEpochDelete} color="red">
              Delete
            </Button>
          </Stack>
        )}
      </Menu.Dropdown>
    </Menu>
  );
};
