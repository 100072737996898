import { TagInputEditor } from "@/components/TagInput/TagInputEditor";
import { useDealDirectoryCategoryTags } from "@/resources/dealDirectories";
import { CustomCellEditorProps } from "@ag-grid-community/react";

export const CategoryTagEditor = ({
  value,
  onValueChange,
  stopEditing,
}: CustomCellEditorProps) => {
  const [tagOptions] = useDealDirectoryCategoryTags();

  return (
    <TagInputEditor
      value={value}
      onValueChange={onValueChange}
      stopEditing={stopEditing}
      tagOptions={tagOptions || []}
      radius={0}
      size="lg"
      autoFocus={true}
      allowMultiple={false}
      callToAction="Change status..."
    />
  );
};
