import { fixLegacyCategoryIdValue } from "@/resources/dealDirectories";
import { getPaletteById, Tag } from "@/resources/tags";
import { CustomCellEditorProps } from "@ag-grid-community/react";
import {
  Combobox,
  ComboboxProps,
  MantineSize,
  Pill,
  PillsInput,
  PillsInputProps,
  ScrollArea,
  useCombobox,
} from "@mantine/core";
import { useState } from "react";

export const TagInputEditor = ({
  value,
  onValueChange,
  stopEditing,
  tagOptions,
  allowMultiple = true,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  data,
  size,
  autoFocus,
  callToAction = "Add Item...",
  ...props
}: Partial<CustomCellEditorProps> &
  ComboboxProps & {
    tagOptions: Tag[] | undefined;
    allowMultiple?: boolean;
    value: string[];
    size?: MantineSize;
    autoFocus?: boolean;
    label?: string;
    styles?: PillsInputProps["styles"];
    callToAction?: string;
  }) => {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => combobox.updateSelectedOptionIndex("active"),
  });
  const [search, setSearch] = useState("");

  const tagIds = fixLegacyCategoryIdValue(value);
  const tags = tagIds.map(
    (tagId) => tagOptions?.find((tag) => tag.id === tagId),
  );

  const handleValueSelect = (tagId: string) => {
    if (allowMultiple) {
      onValueChange?.([...tagIds, tagId]);
    } else {
      onValueChange?.([tagId]);
    }
    stopEditing?.();
    combobox.closeDropdown();
  };

  const handleValueRemove = (tag: Tag) => {
    onValueChange?.(tagIds.filter((tagId) => tagId !== tag.id));
    stopEditing?.();
    combobox.closeDropdown();
  };

  const values = tags.map((tag) => {
    if (!tag) return null;

    return (
      <Pill
        size={size}
        key={tag.id}
        withRemoveButton
        onRemove={() => handleValueRemove(tag)}
        bg={getPaletteById(tag.paletteId)?.backgroundColor}
        c={getPaletteById(tag.paletteId)?.fontColor}
        fw={500}
      >
        {tag.label}
      </Pill>
    );
  });

  const options = (tagOptions || [])
    .filter((tag) =>
      tag.label.toLowerCase().includes(search.trim().toLowerCase()),
    )
    //filter if already selected
    .filter((tag) => !tagIds.includes(tag.id))
    .map((tag) => (
      <Combobox.Option
        value={tag.id}
        key={tag.id}
        active={value?.includes(tag)}
      >
        <Pill
          size={size}
          key={tag.id}
          bg={getPaletteById(tag.paletteId)?.backgroundColor}
          c={getPaletteById(tag.paletteId)?.fontColor}
          fw={500}
        >
          {tag.label}
        </Pill>
      </Combobox.Option>
    ));

  return (
    <Combobox store={combobox} onOptionSubmit={handleValueSelect} {...props}>
      <Combobox.DropdownTarget>
        <PillsInput
          onClick={() => combobox.openDropdown()}
          size={size}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          styles={props?.styles as any}
        >
          <Pill.Group>
            {values}

            <Combobox.EventsTarget>
              <PillsInput.Field
                autoFocus={autoFocus}
                onFocus={() => combobox.openDropdown()}
                onBlur={() => combobox.closeDropdown()}
                value={search}
                placeholder={callToAction}
                onChange={(event) => {
                  combobox.updateSelectedOptionIndex();
                  setSearch(event.currentTarget.value);
                }}
                onKeyDown={(event) => {
                  if (event.key === "Backspace" && search.length === 0) {
                    event.preventDefault();
                    handleValueRemove(value[value.length - 1]);
                  }
                }}
              />
            </Combobox.EventsTarget>
          </Pill.Group>
        </PillsInput>
      </Combobox.DropdownTarget>

      <Combobox.Dropdown>
        <Combobox.Options>
          <ScrollArea.Autosize type="auto" mah={200}>
            {options.length > 0 ? (
              options
            ) : (
              <Combobox.Empty>Nothing found...</Combobox.Empty>
            )}
          </ScrollArea.Autosize>
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};
