import { ActionButtonGroup } from "@/components/AppGrid/ActionButtons/ActionButtonGroup";
import { EditButton } from "@/components/AppGrid/ActionButtons/EditButton";
import { TrashButton } from "@/components/AppGrid/ActionButtons/TrashButton";
import { Route } from "@/paths";
import dealsResource from "@/resources/deals";
import { CustomCellRendererProps } from "@ag-grid-community/react";
import { generatePath } from "react-router-dom";

export const ActionRenderer = (props: CustomCellRendererProps) => {
  return (
    <ActionButtonGroup>
      <EditButton path={generatePath(Route.Deal, { dealId: props.data.id })} />
      <TrashButton
        resource={dealsResource}
        name={props.data.companyName}
        id={props.data.id}
      />
    </ActionButtonGroup>
  );
};
