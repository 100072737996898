import { sidebarAccordionStateAtom } from "@/financeModels/owasco/general";
import { ZIndexOrder } from "@/theme";
import { ActionIcon, Tooltip } from "@mantine/core";
import { useAtom } from "jotai";
import { BiCollapse, BiExpand } from "react-icons/bi";
import { items } from "./manifest";

export const CollapseAllButton = () => {
  const [state, setState] = useAtom(sidebarAccordionStateAtom);

  const allKeys = items.map((item) => item.key);

  return (
    <Tooltip label={Array.isArray(state) ? "Collapse All" : "Expand All"}>
      <ActionIcon
        variant="filled"
        aria-label="Collapse All"
        style={{
          position: "absolute",
          bottom: 6,
          right: 6,
          zIndex: ZIndexOrder.SidebarBottomButtons,
        }}
        onClick={() => {
          if (Array.isArray(state) || state === true) {
            setState(null);
          } else {
            setState(allKeys);
          }
        }}
      >
        {Array.isArray(state) && <BiCollapse />}
        {!Array.isArray(state) && <BiExpand />}
      </ActionIcon>
    </Tooltip>
  );
};
