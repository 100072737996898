import { Route } from "@/paths";
import { ResourceEntry } from "@/resources/_resource";
import { Button, Text } from "@mantine/core";
import { modals } from "@mantine/modals";
import { BiTrash } from "react-icons/bi";
import { generatePath, useNavigate } from "react-router-dom";

export const DeleteButton = ({
  entry,
  confirmationPath,
  getName,
}: {
  entry: ResourceEntry;
  confirmationPath?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getName?: (item: any) => string;
}) => {
  const navigate = useNavigate();
  const [item] = entry.useItem();
  const [remove, loading] = entry.resource.useRemove();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const name = getName ? getName(item) : (item as any)?.name || "Unlabeled";

  const onConfirm = async () => {
    await remove(item!.id);
    navigate(generatePath(confirmationPath || Route.DealTracker));
  };

  const openDeleteModal = async () => {
    await modals.openConfirmModal({
      title: `Delete this ${entry.resource.name}`,

      children: (
        <Text size="md">
          Are you sure you want to delete the <strong>"{name}"</strong> deal?
          This action is destructive and can't be undone.
        </Text>
      ),
      labels: {
        confirm: `Delete this  ${entry.resource.name}`,
        cancel: "Cancel",
      },
      confirmProps: { color: "red" },
      onConfirm: onConfirm,
    });
  };

  return (
    <Button
      loading={loading}
      variant="light"
      color="red"
      leftSection={<BiTrash />}
      fullWidth
      onClick={openDeleteModal}
    >
      Delete
    </Button>
  );
};
