import { auth, firestore } from "@/firebase";
import {
  QueryConstraint,
  Timestamp,
  addDoc,
  collection,
  doc,
  documentId,
  getDoc,
  getDocs,
  query,
  runTransaction,
  setDoc,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import {
  useCollection,
  useDocument,
  useDocumentOnce,
} from "react-firebase-hooks/firestore";

export type BaseResource = {
  id: string;
  creatorId: string;
  status: "published" | "trashed";
  createdAt: Timestamp;
  updatedAt: Timestamp;
};

type LockedProps = keyof BaseResource;

export const buildResource = <T extends BaseResource>({
  collectionPath,
  name,
  getDefaultItem,
  getCreatorId = () => auth.currentUser!.uid,
}: {
  collectionPath: string;
  name?: string;
  getDefaultItem: () => Omit<T, LockedProps>;
  getCreatorId?: () => string;
}) => {
  const collectionRef = collection(firestore, collectionPath);

  const getNewItem = (item?: Partial<T>) => {
    const newItem: Omit<T, LockedProps> = {
      ...getDefaultItem(),
      creatorId: getCreatorId(),
      status: "published",
      createdAt: Timestamp.now(),
      updatedAt: Timestamp.now(),
      ...item,
    };

    return newItem;
  };

  const create = async (item?: Partial<T>) => {
    return await addDoc(collectionRef, getNewItem(item));
  };

  const update = async (itemId: string, item: Partial<T>) => {
    const { id, ...rest } = item;
    return await setDoc(
      doc(collectionRef, itemId || id),
      { ...rest, updatedAt: Timestamp.now() },
      {
        merge: true,
      },
    );
  };

  const remove = async (itemId: string) => {
    return await setDoc(
      doc(collectionRef, itemId),
      { status: "trashed" },
      { merge: true },
    );
  };

  const getQuery = () => {
    return query(
      collectionRef,
      //orderBy("createdAt", "desc"),
      where("creatorId", "==", getCreatorId()),
      where("status", "==", "published"),
    );
  };

  const useQuery = () => {
    return getQuery();
  };

  const useItems = () => {
    const itemQuery = useQuery();
    const [items, loading, error] = useCollection(itemQuery);

    return [
      (items?.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) || []) as T[],
      loading,
      error,
      {
        query: itemQuery,
      },
    ] as const;
  };

  const getItems = async ({
    constraints,
  }: {
    constraints?: QueryConstraint[];
  } = {}) => {
    const q = query(getQuery(), ...(constraints || []));
    const snapshot = await getDocs(q);
    return snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    })) as T[];
  };

  const useItemIds = (ids: string[] | null | undefined) => {
    const itemQuery = useQuery();
    const constrainedQuery = query(
      itemQuery,
      where(documentId(), "in", ids && ids.length > 0 ? ids : ["EMPTY"]),
    );
    const [items, loading, error] = useCollection(
      ids && ids.length > 0 ? constrainedQuery : null,
    );

    return [
      (items?.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) || []) as T[],
      loading,
      error,
      {
        query: itemQuery,
      },
    ] as const;
  };

  const entry = (itemId: string) => {
    const ref = doc(collectionRef, itemId);
    let itemCache: T | null = null;

    const useItem = () => {
      const [snapshot, loading, error] = useDocument(ref);
      const item = { id: snapshot?.id, ...snapshot?.data() } as T | undefined;
      if (item) itemCache = item;

      return [
        snapshot ? item : undefined,
        loading,
        error,
        {
          ref,
          snapshot,
        },
      ] as const;
    };

    const useItemOnce = () => {
      const [snapshot, loading, error] = useDocumentOnce(ref);

      const item = { id: snapshot?.id, ...snapshot?.data() } as T | undefined;
      if (item) itemCache = item;

      return [
        snapshot ? item : undefined,
        loading,
        error,
        {
          ref,
          snapshot,
        },
      ] as const;
    };

    const getItem = async () => {
      const snapshot = await getDoc(ref);
      const item = snapshot.data() as T | undefined;
      if (item) itemCache = item;
      return item;
    };

    const getItemCache = () => {
      return itemCache;
    };

    const update = async (item: Partial<T>) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { id, ...rest } = item;

      return await setDoc(
        ref,
        { ...rest, updatedAt: Timestamp.now() },
        { merge: true },
      );
    };

    const useUpdate = () => {
      const [loading, setLoading] = useState(false);
      const [error, setError] = useState<Error | null>(null);

      const updateItem = async (item: Partial<T>) => {
        setLoading(true);
        try {
          await update(item);
        } catch (error: unknown) {
          setError(error as Error);
        }
        setLoading(false);
      };

      return [updateItem, loading, error] as const;
    };

    return {
      id: itemId,
      useItem,
      useItemOnce,
      getItem,
      update,
      useUpdate,
      ref,
      getItemCache,
      resource,
    } as const;
  };

  const useCreate = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<Error | null>(null);

    const createItem = async (item?: Partial<T>) => {
      setLoading(true);

      try {
        const dealRef = await create(item);
        setLoading(false);
        return dealRef;
      } catch (error: unknown) {
        console.log({ error });
        setError(error as Error);
      }
      setLoading(false);
    };

    return [createItem, loading, error] as const;
  };

  const useUpdate = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<Error | null>(null);

    const updateItem = async (itemId: string, item: Partial<T>) => {
      setLoading(true);
      try {
        await update(itemId, item);
      } catch (error: unknown) {
        setError(error as Error);
      }
      setLoading(false);
    };

    return [updateItem, loading, error] as const;
  };

  const useRemove = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<Error | null>(null);

    const removeItem = async (itemId: string) => {
      setLoading(true);
      try {
        await remove(itemId);
      } catch (error: unknown) {
        setError(error as Error);
        console.log(error);
      }
      setLoading(false);
    };

    return [removeItem, loading, error] as const;
  };

  const resource = {
    collectionPath,
    name,
    collectionRef,
    create,
    update,
    remove,
    useQuery,
    getQuery,
    entry,
    useItems,
    useCreate,
    useUpdate,
    useRemove,
    getNewItem,
    useItemIds,
    getItems,
  };

  return resource;
};

export type Resource = ReturnType<typeof buildResource>;
export type ResourceEntry = ReturnType<Resource["entry"]>;

const runIfNotExistOnce: Map<string, boolean> = new Map();
export const useBuildEntryIfNotExist = (entry: ResourceEntry) => {
  const id = entry.id + entry.resource.name;

  useEffect(() => {
    if (runIfNotExistOnce.get(id)) return;

    runIfNotExistOnce.set(id, true);
    runTransaction(firestore, async (transaction) => {
      const docSnap = await transaction.get(entry.ref);
      if (docSnap.exists()) return;

      transaction.set(entry.ref, entry.resource.getNewItem());
    });
  }, []);
};
