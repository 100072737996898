import { appGridApiAtom } from "@/components/AppGrid/AppGrid";
import { analytics } from "@/firebase";
import { useIsPlanInactive } from "@/hooks/useIsPlanInactive";
import { Resource } from "@/resources/_resource";
import { ApplyColumnStateParams } from "@ag-grid-community/core";
import { Button } from "@mantine/core";
import { logEvent } from "firebase/analytics";
import { useAtomValue } from "jotai";
import { BiSolidAddToQueue } from "react-icons/bi";

export const AppGridCreateItemButton = ({
  resource,
  applySortOnCreate = {
    state: [{ colId: "createdAt", sort: "desc" }],
    defaultState: { sort: null },
  },
  label,
}: {
  resource: Resource;
  applySortOnCreate?: ApplyColumnStateParams;
  label?: string;
}) => {
  const { useCreate, name } = resource;
  const [create, loading] = useCreate();
  const gridApi = useAtomValue(appGridApiAtom);
  const { isPlanInactive } = useIsPlanInactive();

  return (
    <Button
      disabled={isPlanInactive}
      bd={isPlanInactive ? "1px solid var(--mantine-color-gray-4)" : undefined}
      justify="space-between"
      leftSection={<BiSolidAddToQueue size={24} />}
      rightSection={<BiSolidAddToQueue size={24} opacity={0} />}
      size="md"
      onClick={async () => {
        await create();
        gridApi?.applyColumnState(applySortOnCreate);
        logEvent(analytics, `create_${name?.toLowerCase()}`);
      }}
      loading={loading}
    >
      {label || `Create ${name}`}
    </Button>
  );
};
