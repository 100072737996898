import { apiOnCall } from "@/firebase";
import { stripe, useLoadStripe } from "@/stripe";
import { Box, Container, Stack, Text } from "@mantine/core";
import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js";
import { useCallback } from "react";
import { LayoutPage } from "../LayoutPage/LayoutPage";

export const PageCheckout = () => {
  const [isStripeLoading, stripeError] = useLoadStripe();

  const fetchClientSecret = useCallback(async () => {
    const result = await apiOnCall("stripeCreateCheckoutSession");

    return (
      result.data as {
        client_secret: string;
      }
    ).client_secret;
  }, []);

  const options = { fetchClientSecret };

  if (stripeError) {
    return (
      <LayoutPage bg={"gray.0"}>
        <Stack py="sm">
          <Box>
            <Container size="md" maw={900}>
              <Text fz={34}>Payment details</Text>
              <Text>Enter your credit card details here. Cancel anytime.</Text>
            </Container>
          </Box>
          <Box>
            <Text c="red">{stripeError.message}</Text>
          </Box>
        </Stack>
      </LayoutPage>
    );
  }

  return (
    <LayoutPage bg={"gray.0"}>
      <Stack py="sm">
        <Box>
          <Container size="md" maw={900}>
            <Text fz={34}>Payment details</Text>
            <Text>Enter your credit card details here. Cancel anytime.</Text>
          </Container>
        </Box>
        {/* hello world
      <Button
        onClick={() => {
          setShowCheckout(true);
        }}
      >
        Build Check out
      </Button> */}
        {!isStripeLoading && (
          <Box>
            <EmbeddedCheckoutProvider stripe={stripe} options={options}>
              <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
          </Box>
        )}
      </Stack>
    </LayoutPage>
  );
};
