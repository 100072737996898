import { AppGrid } from "@/components/AppGrid/AppGrid";

import { useIsPlanInactive } from "@/hooks/useIsPlanInactive";
import resource from "@/resources/contacts";
import { Box } from "@mantine/core";
import { colDefs, defaultColDef } from "../colDefs";

export const Main = () => {
  const { useQuery, update } = resource;
  const query = useQuery();
  const { isPlanInactive } = useIsPlanInactive();

  return (
    <Box
      bg="gray"
      style={{
        width: "100%",
        height: "100%",
        justifySelf: "stretch",
        alignSelf: "stretch",
        flex: 1,
      }}
    >
      <AppGrid
        id={"contact"}
        query={query}
        columnDefs={colDefs}
        defaultColDef={defaultColDef}
        onCellValueChanged={(event) => {
          update(event.data.id, event.data);
        }}
        isReadOnly={isPlanInactive}
      />
    </Box>
  );
};
