import { loadStripe } from "@stripe/stripe-js";
import { atom, useAtom } from "jotai";
import { useEffect } from "react";

export let stripe: ReturnType<typeof loadStripe> extends Promise<infer T>
  ? T
  : never = null;

export async function initStripe() {
  const key =
    import.meta.env.PROD && !window.location.origin.includes(".web.app")
      ? "pk_live_51QeOKNHwdYLNKQvRnaOoKXvKauT3sf2WEjW3Ucf6B4E5gBqxsHXwa9hpvXSknunINWWTvXep8ecRZDxW9wLeEOY300L9mJYbVt"
      : "pk_test_51QeOKNHwdYLNKQvRG2zWrW1ujVjZqQdtYvoimD98rxEzsAbRTCYGCMTuHMhYOOtI7WidSWKlwZ8qZMHwS5zRF0md00wQkVWzNf";

  stripe = await loadStripe(key);
}

const loadingStripeAtom = atom(true);
const errorStripeAtom = atom<Error | null>(null);

export const useLoadStripe = () => {
  const [loading, setLoading] = useAtom(loadingStripeAtom);
  const [error, setError] = useAtom(errorStripeAtom);

  useEffect(() => {
    if (!loading) return;

    initStripe()
      .then(() => {
        setLoading(false);
      })
      .catch((error: Error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  return [loading, error] as const;
};
