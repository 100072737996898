import { useIsModelReadOnly } from "@/hooks/useModel";
import { NumberInput, NumberInputProps } from "@mantine/core";
import { css } from "vite-plugin-inline-css-modules";

const styles = css`
  .input {
    input {
      color: var(--mantine-color-text) !important;
      opacity: 1 !important;
      cursor: text !important;
    }
  }
`;

export const InputPercent = ({
  value,
  onChange,
  labelWidth = 180,
  label,
  rightSection,
  ...props
}: NumberInputProps & {
  labelWidth?: number;
}) => {
  const [isModelShared] = useIsModelReadOnly();

  return (
    <NumberInput
      className={styles.input}
      disabled={isModelShared}
      allowDecimal={true}
      label={label}
      value={value}
      onChange={onChange}
      rightSection={rightSection || "%"}
      min={0}
      max={100}
      step={0.05}
      ta={"right"}
      placeholder="0"
      hideControls
      styles={{
        root: { display: "flex", alignItems: "center" },
        label: { width: labelWidth, textAlign: "left" },
        wrapper: { flex: 1 },
        input: { textAlign: "right" },
      }}
      {...props}
    />
  );
};
