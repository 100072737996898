import { auth } from "@/firebase";
import { BaseResource, buildResource } from "@/resources/_resource";
import { Tag } from "./tags";

export type DealDirectory = BaseResource & {
  categories: Tag[];
};

const resource = buildResource<DealDirectory>({
  collectionPath: "dealTracker",
  name: "Deal Tracker",
  getDefaultItem: () => ({
    categories: [
      { id: "new", label: "New", paletteId: "sunnyYellow" },
      { id: "working", label: "Working", paletteId: "lavender" },
      { id: "due-diligence", label: "Due Diligence", paletteId: "royalBlue" },
      { id: "ioi-loi", label: "IOI/LOI", paletteId: "limeGreen" },
      { id: "archived", label: "Archived", paletteId: "midnightBlue" },
    ],
  }),
});

export default resource;

const getDealDirectoryId = () => {
  return window.location.search.split("did=")[1] || auth.currentUser?.uid;
};

let entryCache: ReturnType<typeof resource.entry> | null = null;
export const getDealDirectoryEntry = () => {
  const id = getDealDirectoryId();
  if (!id) return null;
  if (entryCache) return entryCache;
  entryCache = resource.entry(id);
  return entryCache;
};

export const useDealDirectoryEntry = () => {
  const entry = getDealDirectoryEntry();
  return entry;
};

export const useDealDirectoryCategoryTags = () => {
  const entry = useDealDirectoryEntry();
  const [item, loading, error] = entry!.useItem();
  return [item?.categories, loading, error] as const;
};

export const fixLegacyCategoryIdValue = (value: string | string[]) => {
  if (Array.isArray(value)) {
    return value;
  }

  if (!value) return [];

  return value.split(",");
};
