import { useAuthContext } from "@/contexts/AuthProvider";
import { Route } from "@/paths";
import { useLoadStripe } from "@/stripe";
import {
  Accordion,
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  Container,
  Divider,
  Group,
  SimpleGrid,
  Stack,
  Text,
} from "@mantine/core";
import {
  LuBarChart,
  LuBookOpen,
  LuBriefcase,
  LuGraduationCap,
  LuLock,
  LuShare,
  LuShare2,
  LuSlack,
  LuUserCheck,
  LuUserPlus,
} from "react-icons/lu";
import { Link } from "react-router-dom";
import { LayoutPage } from "../LayoutPage/LayoutPage";
import { SchoolBanner } from "./SchoolBanner";

export const PagePricing = () => {
  const { user, loading } = useAuthContext();
  useLoadStripe();

  return (
    <LayoutPage>
      <Container size="md" py={60}>
        <Stack align="center" gap="lg">
          <Stack align="center" gap={0}>
            <Text ta={"center"} fw={"bold"} fz={{ base: 28, md: 36 }}>
              Built for Seach Funds:
            </Text>
            <Text ta={"center"} fz={{ base: 28, md: 32 }}>
              Model with Precision and Track Deals Effortlessly
            </Text>
          </Stack>
          <Text size="lg" ta={"center"}>
            Create accurate financial models, leverage an intuitive CRM built
            for Searchers, and access in-product education — all tailored to
            simplify and accelerate your Search Fund journey
          </Text>
          <Text fw={"bold"} size="xl" fz={28} ta={"center"}>
            Start today, no credit card required.
          </Text>
        </Stack>
      </Container>

      <Box
        bg={`linear-gradient(0deg, #fff 24%, #eef3f4 28%, #fff 48%)`}
        pb={90}
      >
        <Container size="md">
          <SimpleGrid cols={{ base: 1, sm: 2 }} spacing={100}>
            <Card withBorder p="xl" radius={0}>
              <Text fw={"bold"} fz={28} c="brand">
                Solo
              </Text>

              <Text size="sm" h={60}>
                Perfect for Searchers who want precision modeling and effortless
                deal tracking—all on their own.
              </Text>
              <Group gap={"xs"} my={"lg"} justify="center" align="center">
                <Text fz={40}>$35</Text>
                <Text fz={"xl"} pt={10}>
                  per month
                </Text>
              </Group>

              <Stack gap={"xs"} justify="center" align="center">
                <Button
                  w={190}
                  size="lg"
                  component={Link}
                  to={user ? Route.Checkout : Route.Signup}
                  loading={loading}
                >
                  {user ? "Buy Now" : "Try for Free"}
                </Button>
                <Text c="dimmed" fz={14} w={180} ta={"center"} lh={1.2}>
                  10-day free trial – no credit card required
                </Text>
              </Stack>

              <Divider my={"xl"} w={"88%"} mx={"auto"} />

              <Text size="xl" mb={"lg"}>
                Solo Includes:
              </Text>
              <Stack gap={"sm"}>
                {soloItems.map((item) => (
                  <Group gap={"xs"} key={item.text}>
                    {item.icon}{" "}
                    <Text fz={"sm"} lh={1}>
                      {item.text}
                    </Text>
                  </Group>
                ))}
              </Stack>
            </Card>
            <Card
              withBorder
              p="xl"
              radius={0}
              pos={"relative"}
              style={{ overflow: "visible" }}
            >
              <Badge
                pos={"absolute"}
                top={-12}
                color="brand"
                radius={0}
                size="lg"
              >
                Best for teams and cohorts
              </Badge>

              <Text fw={"bold"} fz={28} c="brand">
                Team+
              </Text>

              <Text size="sm" h={60}>
                Designed for Searchers managing interns, Partnered Searches, and
                organizations overseeing cohorts of Searchers
              </Text>
              <Group gap={"xs"} my={"lg"} justify="center" align="center">
                <Text fz={40}>Get In Touch</Text>
              </Group>

              <Stack gap={"xs"} justify="center" align="center">
                <Button
                  w={190}
                  size="lg"
                  component={Link}
                  to={Route.ContactSales}
                >
                  Contact Us
                </Button>
                <Text c="dimmed" fz={14} w={180} ta={"center"} lh={1.2}>
                  Let’s tailor a plan to fit your team’s needs
                </Text>
              </Stack>

              <Divider my={"xl"} w={"88%"} mx={"auto"} />

              <Text size="xl" mb={"lg"}>
                Team+ Includes:
              </Text>
              <Stack gap={"sm"}>
                {teamItems.map((item) => (
                  <Group gap={"xs"} key={item.text}>
                    {item.icon}{" "}
                    <Text fz={"sm"} lh={1}>
                      {item.text}
                    </Text>
                  </Group>
                ))}
              </Stack>
            </Card>
          </SimpleGrid>
        </Container>
      </Box>
      <SchoolBanner />
      <Box bg="styledBackgrounds.5" hidden>
        <Container size="lg" py="xl">
          <Stack gap="sm">
            <Group justify="center">
              <Text fw={"bold"} fz={{ base: 20, md: 24 }} c="white">
                Trusted By Searchers
              </Text>
            </Group>
            <SimpleGrid cols={{ base: 1, md: 4 }} spacing="xl">
              {testimonials.map((item) => (
                <Card
                  p={0}
                  key={item.name}
                  radius="0"
                  style={{
                    overflow: "visible",
                    boxShadow:
                      "0 0 0 1px rgba(255, 255, 255, 0.1), 0 2px 4px rgba(0, 0, 0, 0.2), 0 4px 8px rgba(0, 0, 0, 0.2), 0 8px 16px rgba(0, 0, 0, 0.2)",
                  }}
                  mt={40}
                >
                  <Stack h={"100%"} gap="sm">
                    <Stack gap="sm" align="center" p="lg">
                      <Avatar
                        src={item.image}
                        alt="User Image"
                        size={"lg"}
                        mt={-45}
                        style={{
                          border: "2px solid var(--mantine-color-brand-3)",
                        }}
                      />
                      <Stack gap={3}>
                        <Text fw="bold" ta={"center"}>
                          {item.name}
                        </Text>
                        <Text fw="bold" ta={"center"} c="dimmed" size="xs">
                          {item.title}
                        </Text>
                      </Stack>
                      <Text>"{item.text}"</Text>
                    </Stack>
                    <Box
                      bg="gray.3"
                      p="sm"
                      fz={"sm"}
                      ta="center"
                      w={"100%"}
                      mt={"auto"}
                    >
                      {item.company}
                    </Box>
                  </Stack>
                </Card>
              ))}
            </SimpleGrid>
          </Stack>
        </Container>
      </Box>
      <Container size="lg" py={40} mb={100}>
        <Stack>
          <Group justify="center">
            <Text fw={"bold"} fz={{ base: 20, md: 24 }}>
              Frequently Asked Questions
            </Text>
          </Group>
          <Accordion>
            {faqs.map((item) => (
              <Accordion.Item key={item.question} value={item.question}>
                <Accordion.Control>
                  <Text fw={"bold"} size="lg">
                    {item.question}
                  </Text>
                </Accordion.Control>
                <Accordion.Panel>{item.answer}</Accordion.Panel>
              </Accordion.Item>
            ))}
          </Accordion>
        </Stack>
      </Container>
    </LayoutPage>
  );
};

const soloItems = [
  { icon: <LuUserCheck />, text: "1 seat" },
  { icon: <LuBriefcase />, text: "CRM with Unlimited Deal Tracking" },
  { icon: <LuBookOpen />, text: "Unlimited Financial Models" },
  { icon: <LuShare />, text: "Controlled External Sharing" },
  { icon: <LuGraduationCap />, text: "In-Product Education" },
  { icon: <LuSlack />, text: "Financial Analyst Support (Slack)" },
  { icon: <LuLock />, text: "256-bit AES & SSL/TLS Encryption" },
] as const;

const teamItems = [
  { icon: <LuUserCheck />, text: "1 Admin Account" },
  { icon: <LuUserPlus />, text: "Sub-Accounts (Expandable Access)" },
  { icon: <LuBarChart />, text: "Pipeline Analytics" },
  { icon: <LuShare2 />, text: "Seamless Internal Sharing" },
  { icon: <LuBriefcase />, text: "CRM with Unlimited Deal Tracking" },
  { icon: <LuBookOpen />, text: "Unlimited Financial Models" },
  { icon: <LuGraduationCap />, text: "In-Product Education" },
  { icon: <LuShare />, text: "Controlled External Sharing" },
  { icon: <LuSlack />, text: "Financial Analyst Support (Slack)" },
  { icon: <LuLock />, text: "256-bit AES & SSL/TLS Encryption" },
] as const;

const testimonials = [
  {
    name: "John Laroche",
    title: "CEO, Company",
    image: "https://i.pravatar.cc/250?u=7",
    text: "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae.",
    company: "Leaf Capital",
  },
  {
    name: "Jane Perry",
    title: "CTO, Company",
    image: "https://i.pravatar.cc/250?u=13",
    text: "Amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    company: "Perry Brothers LLC",
  },
  {
    name: "Alice Doe",
    title: "CFO, Company",
    image: "https://i.pravatar.cc/250?u=76265",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    company: "Doe Group",
  },
  {
    name: "Bob Smith",
    title: "COO, Company",
    image: "https://i.pravatar.cc/250?u=ll7",
    text: "Lorem Carpe diem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    company: "Smith & Co",
  },
] as const;

const faqs = [
  {
    question: "How go I upgrade my plan?",
    answer:
      "You can upgrade your plan at any time by visiting the billing section of your account dashboard.",
  },
  {
    question: "Can I cancel my subscription?",
    answer:
      "Yes, you can cancel your subscription at any time. Your subscription will remain active until the end of the current billing cycle.",
  },
  {
    question: "Do you offer refunds?",
    answer:
      "We do not offer refunds. If you cancel your subscription, you will retain access to your account until the end of the currentbilling cycle.",
  },
  {
    question: "Can I change my plan?",
    answer:
      "Yes, you can change your plan at any time by visiting the billing section of your account dashboard.",
  },
  {
    question: "Can I add more users to my account?",
    answer:
      "Yes, you can add more users to your account by visiting the billing section of your account dashboard.",
  },
] as const;
