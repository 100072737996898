import { AppGrid } from "@/components/AppGrid/AppGrid";
import { useIsPlanInactive } from "@/hooks/useIsPlanInactive";
import {
  fixLegacyCategoryIdValue,
  getDealDirectoryEntry,
} from "@/resources/dealDirectories";
import resource from "@/resources/deals";
import { getPaletteById } from "@/resources/tags";
import { Box } from "@mantine/core";
import { colDefs, defaultColDef } from "../colDefs";

export const Main = () => {
  const { useQuery, update } = resource;
  const query = useQuery();
  const { isPlanInactive } = useIsPlanInactive();

  return (
    <Box
      bg="gray"
      style={{
        width: "100%",
        height: "100%",
        justifySelf: "stretch",
        alignSelf: "stretch",
        flex: 1,
      }}
    >
      <AppGrid
        isReadOnly={isPlanInactive}
        id={"deal"}
        query={query}
        columnDefs={colDefs}
        defaultColDef={defaultColDef}
        onCellValueChanged={(event) => {
          update(event.data.id, event.data);
        }}
        getRowStyle={(params) => {
          const entry = getDealDirectoryEntry();
          const cache = entry!.getItemCache();
          const id = fixLegacyCategoryIdValue(params.data.categoryId)?.[0];
          const tag = cache?.categories?.find((category) => category.id === id);

          return {
            boxShadow:
              `inset 5px 0 0 0 ` +
              getPaletteById(tag?.paletteId || "")?.backgroundColor,
          };
        }}
      />
    </Box>
  );
};
