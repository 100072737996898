import { Icons } from "@/components/Icons/Icons";
import { CustomCellRendererProps } from "@ag-grid-community/react";
import { ActionIcon } from "@mantine/core";
import { useMemo } from "react";
import { Link } from "react-router-dom";

interface NameRendererProps extends CustomCellRendererProps {
  resourceName: string;
}

export const NameRenderer = (props: NameRendererProps) => {
  const value = useMemo(() => props.value, [props.value]);
  const itemId = useMemo(
    () => props.node.data.id as string,
    [props.node.data.id],
  );

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <span
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          maxWidth: "calc(100% - 25px)",
        }}
      >
        {value ||
          `(unnamed ${(props as { resourceName: string }).resourceName})`}
      </span>

      <ActionIcon
        variant="transparent"
        component={Link}
        to={(
          props as unknown as {
            generatePath: (id: string) => string;
          }
        ).generatePath(itemId)}
        target="_blank"
        ml={"xs"}
        color="gray"
      >
        <Icons.Link />
      </ActionIcon>
    </div>
  );
};
