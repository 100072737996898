import { Button, Container, Group, Stack, Text } from "@mantine/core";
import { Link } from "react-router-dom";
import { LayoutPage } from "../LayoutPage/LayoutPage";

export const PageNotFound = () => {
  return (
    <LayoutPage>
      <Stack h={"100%"} mih="inherit" justify="center">
        <Container ta={"center"}>
          <Stack>
            <Text fz={50}>Page not found.</Text>
            <Text c="dimmed" size="lg" ta="center">
              Page you are trying to open does not exist. You may have mistyped
              the address, or the page has been moved to another URL. If you
              think this is an error contact support.
            </Text>
            <Group justify="center">
              <Button size="md" component={Link} to="/">
                Take me back to the home page
              </Button>
            </Group>
          </Stack>
        </Container>
      </Stack>
    </LayoutPage>
  );
};
