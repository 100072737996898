import { ResourceEntry } from "@/resources/_resource";
import { Note as NoteT } from "@/resources/notes";
import { getShortId } from "@/utils/id";
import {
  ActionIcon,
  Box,
  Button,
  Card,
  Group,
  Menu,
  ScrollArea,
  Spoiler,
  Stack,
  Text,
  Textarea,
  TypographyStylesProvider,
} from "@mantine/core";
import { modals } from "@mantine/modals";
import {
  BoldItalicUnderlineToggles,
  CreateLink,
  ListsToggle,
  MDXEditor,
  linkDialogPlugin,
  linkPlugin,
  listsPlugin,
  toolbarPlugin,
} from "@mdxeditor/editor";
import "@mdxeditor/editor/style.css";
import { Timestamp, arrayRemove, arrayUnion } from "firebase/firestore";
import { memo, useMemo, useRef, useState } from "react";
import { BsThreeDots } from "react-icons/bs";
import Markdown from "react-markdown";
import { css } from "vite-plugin-inline-css-modules";
import { Icons } from "../Icons/Icons";

export const NotesWriter = ({ entry }: { entry: ResourceEntry }) => {
  const [item] = entry.useItem();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const notes = ((item as any)?.notes || []) as NoteT[];

  const onSubmit = async (markdown: string) => {
    if (!markdown) return;
    await entry.update({
      // @ts-expect-error ArrayUnion is not a valid type
      notes: arrayUnion({
        id: getShortId(),
        text: markdown,
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now(),
      } satisfies NoteT),
    });
  };

  if (!item) return null;

  return (
    <ScrollArea h={"calc(100dvh - 43px)"} w={"100%"} bg="gray.4">
      <Stack
        bg="gray.4"
        w="100%"
        style={{
          borderLeft: "1px solid #eee",
        }}
        p={"sm"}
      >
        <Stack gap={"sm"}>
          <Text fw={600} size="sm">
            Notes
          </Text>

          <Editor onSubmit={onSubmit} />
        </Stack>

        <Stack gap={"sm"}>
          <Stack gap={"sm"}>
            {notes.reverse().map((note, index) => (
              <Note key={index} note={note} entry={entry} />
            ))}
          </Stack>
        </Stack>
      </Stack>
    </ScrollArea>
  );
};

export const Editor = memo(
  ({
    onSubmit,
    initialText = "",
    onCancel,
    callToAction = "Add Note",
  }: {
    onSubmit: (text: string) => void;
    initialText?: string;
    onCancel?: () => void;
    callToAction?: string;
  }) => {
    const textRef = useRef(initialText);
    const [isExpanded, setIsExpanded] = useState(!!initialText);

    const plugins = useMemo(
      () => [
        linkPlugin(),
        linkDialogPlugin(),
        listsPlugin(),
        toolbarPlugin({
          //toolbarClassName: !isExpanded ? hiddenStyles.hide : hiddenStyles.show,
          toolbarContents: () => (
            <Group gap={0}>
              <BoldItalicUnderlineToggles options={["Bold", "Italic"]} />
              <ListsToggle options={["bullet", "number"]} />
              <CreateLink />
            </Group>
          ),
        }),
      ],
      [],
    );

    const handleOnSubmit = () => {
      onSubmit(textRef.current);
      setIsExpanded(false);
      textRef.current = "";
    };

    return (
      <Card
        bg={"white"}
        p={"xs"}
        style={{
          overflow: "hidden",
          position: "relative",
        }}
      >
        {!isExpanded && (
          <Textarea
            onFocus={() => setIsExpanded(true)}
            placeholder="Write a note...."
          />
        )}
        {isExpanded && (
          <Box>
            <MDXEditor
              autoFocus
              markdown={initialText}
              onChange={(markdown) => {
                textRef.current = markdown;
                if (markdown && !isExpanded) {
                  setIsExpanded(true);
                }
                if (!markdown && isExpanded) {
                  setIsExpanded(false);
                }
              }}
              plugins={plugins}
            />

            <Group justify="space-between">
              <Button
                color="blue"
                variant="light"
                onClick={handleOnSubmit}
                flex={1}
              >
                {callToAction}
              </Button>
              {onCancel && (
                <Button
                  flex={1}
                  color="gray"
                  variant="light"
                  onClick={onCancel}
                >
                  Cancel
                </Button>
              )}
            </Group>
          </Box>
        )}
      </Card>
    );
  },
);

export const Note = ({
  note,
  entry,
}: {
  note: NoteT;
  entry: ResourceEntry;
}) => {
  const [editingText, setEditingText] = useState<null | string>(null);
  const [update] = entry.useUpdate();
  const [item] = entry.useItem();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const notes = ((item as any)?.notes || []) as NoteT[];

  const updateNote = async (markdown: string) => {
    if (!notes) return;

    await update({
      notes: notes.map((n) =>
        n.id === note.id ? { ...note, text: markdown || "" } : n,
      ),
    } as Partial<ResourceEntry>);
    setEditingText(null);
  };

  const removeNote = async (note: NoteT) => {
    await update({
      // @ts-expect-error ArrayRemove is not a valid type
      notes: arrayRemove(note),
    });
  };

  const openDeleteModal = async () => {
    await modals.openConfirmModal({
      title: "Delete this note?",

      children: (
        <Text size="md">Are you sure you want to delete this note?</Text>
      ),
      labels: {
        confirm: `Delete`,
        cancel: "Cancel",
      },
      confirmProps: { color: "red" },
      onConfirm: async () => {
        removeNote(note);
      },
    });
  };

  return (
    <Card
      shadow="xs"
      style={{
        border: "1px solid var(--mantine-color-gray-2)",
      }}
      onDoubleClick={() => setEditingText(note.text)}
    >
      <Stack gap={"sm"}>
        {editingText !== null ? (
          <Editor
            onSubmit={updateNote}
            initialText={editingText}
            onCancel={() => setEditingText(null)}
            callToAction="Save"
          />
        ) : (
          <Spoiler
            maxHeight={92}
            showLabel="Show more"
            hideLabel="Hide"
            styles={{
              control: {
                width: "100%",
                //fade up to white with a gradient
                background: "linear-gradient(rgba(255, 255, 255, 0), white)",

                position: "absolute",
                top: "calc(100% - 12px)",
                height: 45,
                fontSize: 12,
                textAlign: "left",
                textTransform: "uppercase",
                textDecoration: "underline",
                padding: "20px 0px",
              },
            }}
          >
            <TypographyStylesProvider className={noteStyles.note}>
              <Markdown>{note.text}</Markdown>
            </TypographyStylesProvider>
          </Spoiler>
        )}

        <Group justify="flex-end" align="center">
          <Text size="xs" c="gray.7">
            {note.createdAt.toDate().toLocaleDateString()}
          </Text>

          <Menu shadow="md" width={200}>
            <Menu.Target>
              <ActionIcon color="gray.7" variant="transparent" size={"xs"}>
                <BsThreeDots size={12} />
              </ActionIcon>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Item
                leftSection={<Icons.Edit />}
                onClick={() => setEditingText(note.text)}
              >
                Edit
              </Menu.Item>
              <Menu.Item
                c={"red.7"}
                leftSection={<Icons.Delete />}
                onClick={() => openDeleteModal()}
              >
                Delete
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Group>
      </Stack>
    </Card>
  );
};

const noteStyles = css`
  .note {
    a {
      text-decoration: underline;
    }
  }
`;
