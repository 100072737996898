import { apiOnCall } from "@/firebase";
import { useSubscription } from "@/hooks/useSubscriptions";
import { Route } from "@/paths";
import {
  Anchor,
  Button,
  Card,
  Divider,
  Group,
  Stack,
  Text,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Stripe } from "stripe";

export const Subscriptions = () => {
  const [subscription, loading] = useSubscription();

  if (loading) {
    return null;
  }

  if (!subscription) {
    return (
      <Stack>
        <Card withBorder radius={0}>
          No subscriptions found.{" "}
          <Anchor inline underline="always" component={Link} to={Route.Pricing}>
            Please select a plan to get started.
          </Anchor>
        </Card>
      </Stack>
    );
  }

  return <Subscription subscription={subscription} />;
};

const Subscription = ({
  subscription,
}: {
  subscription: Stripe.Subscription;
}) => {
  const [billingPortalUrl, setBillingPortalUrl] = useState<string | null>(null);

  const isTrialActive =
    subscription?.trial_end &&
    new Date(subscription.trial_end * 1000) > new Date() &&
    (!subscription?.current_period_start ||
      new Date(subscription.trial_end * 1000) >
        new Date(subscription.current_period_start * 1000));

  const isSubscriptionEnding = subscription?.cancel_at_period_end;
  const isActive = ["active", "trialing"].includes(subscription?.status);

  // get billing portal session on mount
  useEffect(() => {
    const fetchBillingPortalSession = async () => {
      const result = await apiOnCall("stripeCreateBillingPortalSession");

      setBillingPortalUrl((result.data as { url: string }).url);
    };
    fetchBillingPortalSession();
  }, []);

  return (
    <Card withBorder p="lg" radius={0}>
      <Stack gap={0} mb="xs">
        <Text c="dimmed">CURRENT PLAN</Text>
        <Divider />
      </Stack>
      <Group justify="space-between" align="center">
        <Stack>
          <Stack gap={0}>
            <Text fw={"bold"} fz={"lg"}>
              Solo Plan
            </Text>
            {isActive && (
              <>
                <Text>$35.00 per month</Text>
                {isTrialActive && (
                  <Text>
                    Your trial ends on:{" "}
                    {new Date(
                      subscription!.trial_end! * 1000,
                    ).toLocaleDateString()}
                  </Text>
                )}

                {subscription?.current_period_start &&
                  subscription?.current_period_end && (
                    <>
                      <Text>
                        Current billing period:{" "}
                        {new Date(
                          subscription.current_period_start * 1000,
                        ).toLocaleDateString()}{" "}
                        -{" "}
                        {new Date(
                          subscription.current_period_end * 1000,
                        ).toLocaleDateString()}
                      </Text>
                      {isSubscriptionEnding && (
                        <Text className="text-red-500 font-semibold mt-1">
                          Your subscription will end on:{" "}
                          {new Date(
                            subscription.current_period_end * 1000,
                          ).toLocaleDateString()}
                        </Text>
                      )}
                    </>
                  )}
                <Button
                  loading={!billingPortalUrl}
                  component={Link}
                  to={billingPortalUrl || ""}
                >
                  Manage Subscription
                </Button>
              </>
            )}
          </Stack>

          {!isActive && (
            <Stack>
              <Text>Your subscription is currently inactive.</Text>
              <Button component={Link} to={Route.Checkout}>
                Purchase a subscription
              </Button>
            </Stack>
          )}
        </Stack>
      </Group>
    </Card>
  );
};
