import { useAuthContext } from "@/contexts/AuthProvider";
import { auth, firestore } from "@/firebase";
import { updateProfile } from "firebase/auth";
import { collection, doc, setDoc } from "firebase/firestore";
import { useState } from "react";
import { useDocument } from "react-firebase-hooks/firestore";

export type UserProfile = {
  displayName: string;
  email: string;
};

const collectionRef = collection(firestore, "userProfile");

export const getDocumentRef = (id?: string) => {
  return doc(collectionRef, id || auth?.currentUser?.uid);
};

export const useUserProfile = (id?: string) => {
  const ref = getDocumentRef(id);

  const [snapshot, loading, error] = useDocument(ref);

  return [
    !snapshot
      ? undefined
      : ({
          id: snapshot.id,
          displayName: "",
          email: "",
          ...snapshot?.data(),
        } as UserProfile),
    loading,
    error,
  ] as const;
};

export const syncUserProfileToAuth = async () => {
  if (!auth.currentUser) {
    return;
  }

  setDoc(getDocumentRef(), {
    displayName: auth.currentUser.displayName,
    email: auth.currentUser.email,
  });
};

export const useUpdateUserProfile = () => {
  const { user } = useAuthContext();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const update = async (displayName: string) => {
    if (!user) return;
    try {
      setLoading(true);
      await updateProfile(user, {
        displayName,
      });

      await syncUserProfileToAuth();
      setLoading(false);
      //alert("Profile updated successfully");
    } catch (error) {
      console.error("Error updating profile:", error);
      setError(
        "Failed to update profile. Please try again or contact support.",
      );
      //alert("Failed to update profile");
    }
  };

  return [update, loading, error] as const;
};
