import { Group } from "@mantine/core";

export const ActionButtonGroup = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <Group align="center" w={"100%"} h={"100%"} gap="xs">
      {children}
    </Group>
  );
};
