import { Icons } from "@/components/Icons/Icons";
import { apiOnCall, auth } from "@/firebase";
import { useModelOnce } from "@/hooks/useModel";
import {
  useModelSharingAddEmail,
  useModelSharingAllowedEmails,
  useModelSharingIsPublic,
  useModelSharingRemoveEmail,
  useModelSharingTogglePublic,
} from "@/hooks/useModelSharing";
import { Model } from "@/resources/models";
import {
  ActionIcon,
  Button,
  Card,
  CopyButton,
  Divider,
  Group,
  ScrollArea,
  Stack,
  Switch,
  Table,
  Text,
  TextInput,
  Tooltip,
  UnstyledButton,
} from "@mantine/core";
import { modals } from "@mantine/modals";
import { useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { FaCircleXmark } from "react-icons/fa6";
import { RiQuestionFill, RiUserAddFill } from "react-icons/ri";
import { useParams } from "react-router-dom";

export const SharingButton = () => {
  const params = useParams();
  const modelId = params.modelId;
  const [model] = useModelOnce(modelId);

  if (!model) return null;

  return (
    <Button
      size="xs"
      variant="outline"
      fz={"sm"}
      onClick={() => openShareModal(model)}
      leftSection={
        <Icons.Share style={{ marginLeft: 3, marginRight: -2 }} size={16} />
      }
    >
      Share
    </Button>
  );
};

export const ModelSharingModal = ({ model }: { model: Model }) => {
  const modelId = model.id;
  const [isPublic] = useModelSharingIsPublic(modelId);
  const [togglePublic] = useModelSharingTogglePublic(modelId);
  const [emails] = useModelSharingAllowedEmails(modelId);
  const [add] = useModelSharingAddEmail(modelId);
  const [remove] = useModelSharingRemoveEmail(modelId);
  const [email, setEmail] = useState("");
  const [notifyUser, setNotifyUser] = useState(false);

  const close = () => modals.closeAll();

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (email === "") return;
    if (emails.includes(email)) return;

    if (notifyUser) {
      apiOnCall("modelNotifyShareViaEmail", {
        modelName: model?.name,
        senderName: auth.currentUser?.displayName,
        senderEmail: auth.currentUser?.email,
        modelUrl: "https://etaiq.com/model/" + model?.id,
        recipientEmail: email,
      });
    }

    setEmail("");
    setNotifyUser(false);
    add(email);
  };

  return (
    <Stack gap={"lg"}>
      <Stack>
        <Text fz={"lg"}>
          Anyone with access with be able to view changes and clone this model.
        </Text>

        <form onSubmit={onSubmit}>
          <Stack gap={"sm"}>
            <Text fz={"lg"} fw={"bold"} lh={1}>
              People With Access
              <Tooltip label="Privately share a read-only version of the model. Recipients will see your updates and can clone it.">
                <ActionIcon variant="transparent" size={"lg"}>
                  <RiQuestionFill size={20} />
                </ActionIcon>
              </Tooltip>
            </Text>
            <Group gap={"sm"}>
              <TextInput
                size="md"
                flex={1}
                type="email"
                placeholder="Email (e.g sample@email.com)"
                value={email}
                onChange={(event) => setEmail(event.currentTarget.value)}
              />
              <Button
                size="md"
                type="submit"
                leftSection={<RiUserAddFill size={20} />}
              >
                Add User
              </Button>
            </Group>
            <Switch
              size="md"
              checked={notifyUser}
              onChange={(event) => setNotifyUser(event.currentTarget.checked)}
              label="Notify via email model has been shared"
            />
          </Stack>
        </form>

        <Card withBorder padding={0}>
          {emails.length === 0 && (
            <Text p={"md"} ta={"center"} size="sm">
              No emails can view this model.
            </Text>
          )}

          <ScrollArea.Autosize mah={168} offsetScrollbars={false}>
            <Table striped>
              {emails.map((email, index) => (
                <Group
                  justify="space-between"
                  key={index}
                  bg={index % 2 === 0 ? "white" : "gray.2"}
                  align="center"
                  p={"xs"}
                >
                  <Text key={index}>{email}</Text>
                  <UnstyledButton onClick={() => remove(email)}>
                    <FaCircleXmark
                      color="var(--mantine-color-red-8)"
                      size={20}
                    />
                  </UnstyledButton>
                </Group>
              ))}
            </Table>
          </ScrollArea.Autosize>
        </Card>
      </Stack>
      <Divider />
      <Stack>
        <Text fz={"lg"} fw={"bold"}>
          Public Access
        </Text>
        <Switch
          label="Anyone with link can view and clone model"
          checked={isPublic}
          onChange={togglePublic}
          size="md"
        />

        {isPublic && (
          <TextInput
            rightSectionWidth={106}
            readOnly
            value={window.location.href}
            rightSection={
              <CopyButton value={window.location.href}>
                {({ copied, copy }) => (
                  <Button
                    w={100}
                    size="compact-md"
                    color={copied ? "teal" : "blue"}
                    onClick={copy}
                    radius={4}
                    fz={"sm"}
                  >
                    {copied ? <FaCheckCircle /> : " Copy Link "}
                  </Button>
                )}
              </CopyButton>
            }
          />
        )}
      </Stack>

      <Group justify="center">
        <Button variant="outline" onClick={close} w={"100%"}>
          Done
        </Button>
      </Group>
    </Stack>
  );
};

export const openShareModal = (model: Model) => {
  modals.open({
    title: `Share Model "${model.name}"`,
    children: <ModelSharingModal model={model} />,
  });
};
