import { firestore } from "@/firebase";
import { collection, query, where } from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import Stripe from "stripe";
import { useUserAccount } from "./useUserAccount";

const subscriptionsCollectionRef = collection(firestore, "stripeSubscription");

export const useSubscriptions = () => {
  const [userAccount, loadingUserAccount] = useUserAccount();

  const [snapshot, loadingSubscriptions, error] = useCollection(
    userAccount?.customerId
      ? query(
          subscriptionsCollectionRef,
          where("customer", "==", userAccount?.customerId),
        )
      : null,
  );

  const loading =
    loadingUserAccount ||
    loadingSubscriptions ||
    userAccount === undefined ||
    (userAccount !== null && snapshot === undefined);

  const result = snapshot?.docs.map((doc) => doc.data() as Stripe.Subscription);

  return [result, loading, error] as const;
};

export const useSubscription = () => {
  const [subscriptions, loading, error] = useSubscriptions();

  const currentSubscription =
    subscriptions === null
      ? subscriptions
      : subscriptions?.find(
          (subscription) =>
            subscription.status === "active" ||
            subscription.status === "trialing",
        );

  return [currentSubscription, loading, error] as const;
};
