import { appGridApiAtom } from "@/components/AppGrid/AppGrid";
import { ActionIcon, TextInput, TextInputProps } from "@mantine/core";
import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { IoClose } from "react-icons/io5";

export const AppGridSearch = ({ ...props }: TextInputProps) => {
  const api = useAtomValue(appGridApiAtom);
  const [value, setValue] = useState("");

  useEffect(() => {
    api?.setGridOption("quickFilterText", value);
  }, [api, value]);

  return (
    <TextInput
      label="Search"
      leftSectionPointerEvents="none"
      leftSection={<FaSearch />}
      placeholder="Search..."
      size="md"
      onChange={(e) => setValue(e.currentTarget.value)}
      value={value}
      rightSection={
        value ? (
          <ActionIcon
            size={18}
            color="gray"
            variant="transparent"
            onClick={() => setValue("")}
          >
            <IoClose />
          </ActionIcon>
        ) : null
      }
      styles={{
        label: {
          fontSize: 14,
          fontWeight: 600,
        },
      }}
      {...props}
    />
  );
};
