import { BaseResource, buildResource } from "@/resources/_resource";
import { query, where } from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import dealsResource, { Deal } from "./deals";
import { Note } from "./notes";

export type Contact = BaseResource & {
  name: string;
  email: string;
  phone: string;
  website: string;
  firm: string;
  jobTitleIds: string[];
  location: string;
  remarks: string;
  notes: Note[] | null;
};

const resource = buildResource<Contact>({
  collectionPath: "contact",
  name: "Contact",
  getDefaultItem: () => ({
    name: "Untitled Contact " + new Date().toLocaleDateString(),
    email: "",
    phone: "",
    website: "",
    firm: "",
    jobTitleIds: [],
    location: "",
    remarks: "",
    notes: null,
  }),
});

const getContactId = () => {
  return window.location.pathname.split("/contact/")[1].split("/")[0];
};

export const useContactEntry = () => {
  return resource.entry(getContactId());
};

export const useContactDeals = () => {
  const { getQuery } = dealsResource;

  const q = query(
    getQuery(),
    where("contactIds", "array-contains", getContactId()),
  );
  const [snapshot, loading, error] = useCollection(q);

  return [
    snapshot?.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    })) as Deal[],
    loading,
    error,
  ] as const;
};

export default resource;
