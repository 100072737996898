import { DocumentData, onSnapshot, Query } from "firebase/firestore";
import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { appGridApiAtom } from "./AppGrid";

export const useKeepGridInSyncWithQuery = (
  q: Query<DocumentData, DocumentData>,
) => {
  const gridApi = useAtomValue(appGridApiAtom);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!gridApi) return;

    let refresh = true;
    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        if (loading) setLoading(false);
        const transaction: {
          add: { id: string }[];
          remove: { id: string }[];
          update: { id: string }[];
        } = { add: [], remove: [], update: [] };

        if (refresh) {
          // remove all items for grid
          gridApi.forEachNode((node) => transaction.remove.push(node.data));
        }

        snapshot?.docChanges().forEach((change) => {
          const item = { ...change.doc.data(), id: change.doc.id };

          if (change.type === "added") {
            if (refresh) {
              if (!gridApi.getRowNode(item.id)) {
                transaction.add.push(item);
              }

              transaction.remove = transaction.remove.filter(
                (r) => r.id !== item.id,
              );

              return;
            }

            if (gridApi.getRowNode(item.id)) return;
            transaction.add.push(item);
          } else if (change.type === "removed") {
            transaction.remove.push(item);
          } else if (change.type === "modified") {
            transaction.update.push(item);
          }
        });

        gridApi.applyTransaction(transaction);
        refresh = false;
      },
      console.error,
    );

    return () => unsubscribe();
  }, [gridApi, q]);

  return { loading };
};
