import { dealDocumentAtom } from "@/atoms/deal";
import { LayoutWorkstation } from "@/components/LayoutWorkstation/LayoutWorkStation";
import { Group, Text } from "@mantine/core";
import { useAtom } from "jotai";

export const Header = () => {
  const [[deal]] = useAtom(dealDocumentAtom);

  return (
    <LayoutWorkstation.Header>
      <Group ml={"xs"} gap={6}>
        <Text mt={".13em"} fw={600}>
          {deal?.companyName || "( Untitled Company )"}
        </Text>
      </Group>
    </LayoutWorkstation.Header>
  );
};
