import { ResourceEntry } from "@/resources/_resource";
import { Stack } from "@mantine/core";
import { DeleteButton } from "./DeleteButton";
import { Timestamps } from "./Timestamps";

export const EntrySidebarActions = ({
  entry,
  confirmationPath,
}: {
  entry: ResourceEntry;
  confirmationPath: string;
}) => {
  return (
    <Stack>
      <Timestamps entry={entry} />
      <DeleteButton entry={entry} confirmationPath={confirmationPath} />
    </Stack>
  );
};
