import { useAuthContext } from "@/contexts/AuthProvider";
import { auth } from "@/firebase";
import { Button, Stack, Text } from "@mantine/core";
import { useSignOut } from "react-firebase-hooks/auth";

export const Actions = () => {
  const { user } = useAuthContext();
  const [signout] = useSignOut(auth);

  return (
    <Stack>
      <Stack gap={4}>
        <Text size="xs">User Created: {user?.metadata.creationTime}</Text>
        <Text size="xs">User UUID: {user?.uid}</Text>
      </Stack>
      <Button variant="outline" onClick={signout} radius={999}>
        Log out
      </Button>
    </Stack>
  );
};
