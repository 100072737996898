import { useAuthContext } from "@/contexts/AuthProvider";
import { router } from "@/main";
import { Route } from "@/paths";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { PageVerifyYourEmail } from "../PageVerifyYourEmail/PageVerifyYourEmail";

export const RequireAuth = () => {
  const { user, loading } = useAuthContext();

  useEffect(() => {
    if (!loading && !user) {
      if (location.pathname === Route.Login) return;

      router.navigate(Route.Login, {
        state: { returnTo: location.pathname },
      });
    }
  }, [loading, user]);

  if (loading) return null;
  if (!user?.emailVerified) return <PageVerifyYourEmail />;

  return <Outlet />;
};
