import { Group } from "@mantine/core";
import { Content } from "./Content";
import { Header } from "./Header";
import { Main } from "./Main";
import { Panel } from "./Panel";
import { Sidebar } from "./Sidebar";

export const LayoutWorkstation = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <Group h={"100%"} flex={1} align="stretch" gap={0} miw={0} bg={"gray.3"}>
      {children}
    </Group>
  );
};

LayoutWorkstation.Sidebar = Sidebar;
LayoutWorkstation.Header = Header;
LayoutWorkstation.Main = Main;
LayoutWorkstation.Panel = Panel;
LayoutWorkstation.Content = Content;
