import { coreModelAtom } from "@/financeModels/owasco/model";
import { hasFinishedLoadingModelOnMountAtom } from "@/financeModels/owasco/save";
import { useHash } from "@mantine/hooks";
import { useAtom, useAtomValue } from "jotai";
import { useEffect } from "react";

export const useDownloadUploadModel = () => {
  const [hash] = useHash();
  const [model, setModel] = useAtom(coreModelAtom);
  const hasLoadedModel = useAtomValue(hasFinishedLoadingModelOnMountAtom);

  useEffect(() => {
    if (!model) return;
    if (!hasLoadedModel) return;

    console.log("hash", hash, model);

    if (hash === "#download") {
      const a = document.createElement("a");
      a.href = URL.createObjectURL(
        new Blob([JSON.stringify(model)], { type: "application/json" }),
      );
      a.download = `${new Date()
        .toISOString()
        .replace(/:/g, "-")
        .replace(/\..+/, "")}.json`;
      a.click();
    }

    if (hash === "#upload") {
      const json = prompt("Paste JSON here");
      if (!json) return;

      setTimeout(() => {
        try {
          setModel(JSON.parse(json));
          alert("Model uploaded");
        } catch (e) {
          alert("Failed to parse JSON");
        }
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasLoadedModel]);
};
