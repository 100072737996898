import { useBuildEntryIfNotExist } from "@/resources/_resource";
import { useDealDirectoryEntry } from "@/resources/dealDirectories";
import { Loader } from "@mantine/core";
import { Main } from "./Main/Main";
import { Sidebar } from "./Sidebar/Sidebar";

export const PageDealTracker = () => {
  const entry = useDealDirectoryEntry();
  useBuildEntryIfNotExist(entry!);

  const [item] = entry!.useItem();
  if (!item)
    return (
      <Loader
        size={50}
        pos={"absolute"}
        left={"50%"}
        top={"40%"}
        opacity={0.1}
      />
    );

  return (
    <>
      <Sidebar />
      <Main />
    </>
  );
};
