import { LayoutWorkstation } from "@/components/LayoutWorkstation/LayoutWorkStation";
import { Deal } from "@/resources/deals";
import { textFontFamily } from "@/theme";
import {
  Badge,
  Group,
  NumberInput,
  SimpleGrid,
  TextInput,
} from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { Timestamp } from "firebase/firestore";

export const EditDealForm = ({
  initialValues,
  onValuesChange,
}: {
  initialValues?: Partial<Deal>;
  onSubmit?: (values: Partial<Deal>) => void;
  callToAction?: string;
  onValuesChange?: (values: Partial<Deal>) => void;
}) => {
  const form = useForm({
    mode: "uncontrolled",

    initialValues: {
      companyName: "",
      location: "",
      industry: "",
      listingLink: "",
      brokerage: "",
      broker: "",
      purchasePrice: null,
      revenue: null,
      cashFlow: null,
      establishedAt: null,
      ...initialValues,
    } satisfies Partial<Deal>,
    onValuesChange: (values) => {
      onValuesChange?.(values);
    },
  });

  const deal = form.getValues();

  const purchaseMultiple =
    (deal?.purchasePrice || 0) / (deal?.cashFlow || 0.000001);

  return (
    <>
      <LayoutWorkstation.Panel label="Company">
        <Group>
          <TextInput
            key={form.key("companyName")}
            {...form.getInputProps("companyName")}
            label="Company Name"
            placeholder=""
          />
          <TextInput
            key={form.key("location")}
            {...form.getInputProps("location")}
            label="Location"
            placeholder=""
          />
          <DateInput
            w={160}
            label="Year Established"
            valueFormat="MM/DD/YYYY"
            placeholder="MM/DD/YYYY"
            value={deal?.establishedAt?.toDate()}
            onChange={(date) => {
              if (!date) return;
              form.setFieldValue("establishedAt", Timestamp.fromDate(date));
            }}
          />
          <TextInput
            key={form.key("industry")}
            {...form.getInputProps("industry")}
            label="Industry"
            placeholder=""
          />
        </Group>
      </LayoutWorkstation.Panel>
      <SimpleGrid cols={2}>
        <LayoutWorkstation.Panel label="Brokerage">
          <TextInput
            key={form.key("listingLink")}
            {...form.getInputProps("listingLink")}
            label="Deal Listing Link"
            placeholder=""
          />
          <TextInput
            key={form.key("brokerage")}
            {...form.getInputProps("brokerage")}
            label="Brokerage Firm"
            placeholder=""
          />
          <TextInput
            key={form.key("broker")}
            {...form.getInputProps("broker")}
            label="Broker Name"
            placeholder=""
          />
        </LayoutWorkstation.Panel>
        <LayoutWorkstation.Panel
          label={
            <Group justify="space-between">
              Listed Financials
              {purchaseMultiple > 0.5 && purchaseMultiple < 1000 && (
                <Badge tt={"capitalize"} color="gray" size="md">
                  Purchase Multiple:{" "}
                  <strong>{purchaseMultiple.toFixed(2)}x</strong>
                </Badge>
              )}
            </Group>
          }
        >
          <NumberInput
            label="Purchase Price"
            {...numberInputDefaultProps}
            key={form.key("purchasePrice")}
            {...form.getInputProps("purchasePrice")}
            styles={{
              input: {
                fontFamily: textFontFamily,
              },
            }}
          />
          <NumberInput
            label="Cash Flow / SDE"
            {...numberInputDefaultProps}
            key={form.key("cashFlow")}
            {...form.getInputProps("cashFlow")}
            styles={{
              input: {
                fontFamily: textFontFamily,
              },
            }}
          />

          <NumberInput
            label="Gross Revenue"
            {...numberInputDefaultProps}
            key={form.key("revenue")}
            {...form.getInputProps("revenue")}
            styles={{
              input: {
                fontFamily: textFontFamily,
              },
            }}
          />
        </LayoutWorkstation.Panel>
      </SimpleGrid>
    </>
  );
};

const numberInputDefaultProps = {
  thousandSeparator: ",",
  prefix: "$",
  allowNegative: false,
  decimalScale: 2,
  hideControls: true,
};
