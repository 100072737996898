import { useUserProfile } from "@/hooks/useUserProile";
import { Model } from "@/resources/models";
import { CustomCellRendererProps } from "@ag-grid-community/react";

export const ModelOwnerRenderer = (props: CustomCellRendererProps) => {
  const model = props.data as Model;
  const [profile] = useUserProfile(model.creatorId);

  return profile?.email;
};
