import { LayoutWorkstation } from "@/components/LayoutWorkstation/LayoutWorkStation";
import { Logo } from "@/components/Logo/Logo";
import { useIsUserModelCreator } from "@/hooks/useModel";
import { Route } from "@/paths";
import { Box, Group, Stack, Tabs } from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";
import { BiSolidBinoculars } from "react-icons/bi";
import { FaPiggyBank } from "react-icons/fa6";
import { MdTableChart, MdViewTimeline } from "react-icons/md";
import { RiEdit2Fill } from "react-icons/ri";
import { TbChartDonutFilled } from "react-icons/tb";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { css } from "vite-plugin-inline-css-modules";
import { MakeACopyButton } from "./MakeACopyButton";
import { PanelAmortization } from "./PanelAmortization/PanelAmortization";
import { PanelHistoricals } from "./PanelHistoricals/PanelHistoricals";
import { PanelInputs } from "./PanelInputs/PanelInputs";
import { PanelOverview } from "./PanelOverview/PanelOverview";
import { PanelProjections } from "./PanelProjections/PanelProjections";
import { PanelROI } from "./PanelROI/PanelROI";
import { SharingButton } from "./SharingButton";

const styles = css`
  .tab {
    &[data-active] {
      border-radius: 0;
      background-color: var(--mantine-color-accent-0);
      border-bottom: 4px solid var(--mantine-color-accent-3);
    }
  }
`;

const iconProps = { size: 19 };

const tabsData = [
  {
    modelTabId: "edit",
    icon: <RiEdit2Fill {...iconProps} />,
    panel: <PanelInputs />,
    label: "Inputs",
  },
  {
    modelTabId: "historicals",
    icon: <MdViewTimeline {...iconProps} />,
    panel: <PanelHistoricals />,
    label: "Historicals",
  },
  {
    modelTabId: "amortization",
    icon: <MdTableChart {...iconProps} />,
    panel: <PanelAmortization />,
    label: "Amortization",
  },
  {
    modelTabId: "projections",
    icon: <BiSolidBinoculars {...iconProps} />,
    panel: <PanelProjections />,
    label: "Projections",
  },
  {
    modelTabId: "roi",
    icon: <TbChartDonutFilled {...iconProps} />,
    panel: <PanelROI />,
    label: "ROI Metrics",
  },
  {
    modelTabId: "overview",
    icon: <FaPiggyBank {...iconProps} />,
    panel: <PanelOverview />,
    label: "Overview",
  },
];

export const Main = () => {
  const { modelTabId } = useParams();

  const currentTab = modelTabId
    ? tabsData.find((tab) => tab.modelTabId.includes(modelTabId))
    : tabsData[1];

  return (
    <LayoutWorkstation.Main>
      <Header />
      <LayoutWorkstation.Content p="xs">
        {currentTab?.panel}
      </LayoutWorkstation.Content>
    </LayoutWorkstation.Main>
  );
};

const Header = () => {
  const navigate = useNavigate();
  const { modelTabId, modelId = "error" } = useParams();
  const [isUserModelCreator] = useIsUserModelCreator();
  const { width } = useViewportSize();

  const currentTab = modelTabId
    ? tabsData.find((tab) => tab.modelTabId.includes(modelTabId))
    : tabsData[1];

  return (
    <LayoutWorkstation.Header>
      <Group px={4} w={"100%"} h={"100%"} align="center" gap={6}>
        <Box hiddenFrom="md">
          <Logo variant="dark" w={60} to={Route.DealTracker} />
        </Box>
        <Stack h={"100%"} justify="flex-end">
          <Tabs
            classNames={{ tab: styles.tab }}
            color="infomatic.3"
            keepMounted={false}
            value={currentTab?.modelTabId}
            onChange={(modelTabId) =>
              navigate(
                generatePath(Route.ModelTab, {
                  modelTabId,
                  modelId,
                }),
              )
            }
          >
            <Tabs.List>
              {tabsData.map((tab) => (
                <Tabs.Tab
                  key={tab.modelTabId}
                  value={tab.modelTabId}
                  //left section if width > 1000
                  leftSection={width > 1123 || width <= 777 ? tab.icon : null}
                  hiddenFrom={tab.label === "Inputs" ? "md" : "base"}
                  fw={600}
                  px={{
                    sm: "xs",
                    md: 8,
                    lg: 15,
                  }}
                  children={width > 777 ? tab.label : null}
                />
              ))}
            </Tabs.List>
          </Tabs>
        </Stack>

        <Group ml={"auto"}>
          {isUserModelCreator && <SharingButton />}
          {!isUserModelCreator && <MakeACopyButton />}
        </Group>
      </Group>
    </LayoutWorkstation.Header>
  );
};
