import {
  BigDollarInput,
  BigDollarInputProps,
} from "@/components/BigDollarInput/BigDollarInput";
import { useIsModelReadOnly } from "@/hooks/useModel";
import { css } from "vite-plugin-inline-css-modules";
import { SidebarInputProps } from "./types";

const styles = css`
  .input {
    input {
      color: var(--mantine-color-text) !important;
      opacity: 1 !important;
      cursor: text !important;
    }
  }
`;

export const InputDollar = ({
  labelWidth = 181,
  ...props
}: SidebarInputProps & BigDollarInputProps) => {
  const [isModelShared] = useIsModelReadOnly();

  return (
    <BigDollarInput
      className={styles.input}
      disabled={isModelShared}
      size="sm"
      styles={{
        root: { display: "flex", alignItems: "center" },
        label: { width: labelWidth },
        wrapper: { flex: 1 },
      }}
      {...props}
    />
  );
};
