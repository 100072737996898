import { Icons } from "@/components/Icons/Icons";
import { useModel } from "@/hooks/useModel";
import { clone } from "@/hooks/useModels";
import { Route } from "@/paths";
import { Button } from "@mantine/core";
import { useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";

export const MakeACopyButton = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [model] = useModel();

  const onClick = async () => {
    setLoading(true);
    const m = await clone(model!.id);

    navigate(
      generatePath(Route.Model, {
        modelId: m!.id,
      }),
    );
  };

  return (
    <Button
      loading={loading}
      onClick={onClick}
      size="xs"
      color="blue"
      fz={"sm"}
      ml="auto"
      leftSection={
        <Icons.Clone style={{ marginLeft: 3, marginRight: -2 }} size={14} />
      }
    >
      Make A Copy
    </Button>
  );
};
