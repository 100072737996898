import { ViewportSizeName } from "@/components/ViewportSizeName/ViewportSizeName";
import { routes } from "@/routes";
import { resolver, theme } from "@/theme.ts";
import { MantineProvider } from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { AuthProvider } from "./contexts/AuthProvider";
import { getSentry } from "./sentry";

// css files
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "@mdxeditor/editor/style.css";
import "./styles/flash.css";
import "./styles/liteYouTubeEmbed.css";

getSentry();

export const router = createBrowserRouter(routes, { basename: `/` });

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <MantineProvider theme={theme} cssVariablesResolver={resolver}>
      <ModalsProvider>
        <AuthProvider>
          <Suspense>
            <RouterProvider router={router} />
          </Suspense>
        </AuthProvider>
        <ViewportSizeName />
      </ModalsProvider>
    </MantineProvider>
  </React.StrictMode>,
);
