import { EditContactForm } from "@/components/EditContactForm/EditContactForm";
import { NotesWriter } from "@/components/NotesWriter/NotesWriter";
import { useIsPlanInactive } from "@/hooks/useIsPlanInactive";
import { Contact, useContactEntry } from "@/resources/contacts";
import { Flex, Group, ScrollArea, Stack } from "@mantine/core";

export const Main = () => {
  const entry = useContactEntry();
  const { inactiveElementProps } = useIsPlanInactive();

  return (
    <Flex w={"100%"} {...inactiveElementProps}>
      <ScrollArea h={"calc(100dvh - 43px)"} w={{ base: "100%", lg: "64%" }}>
        <Stack p="sm">
          <EditContact />
        </Stack>
      </ScrollArea>
      <Group flex={1} bg="white" align="top" miw={300}>
        <NotesWriter entry={entry} />
      </Group>
    </Flex>
  );
};

const EditContact = () => {
  const entry = useContactEntry();
  const [contact] = entry.useItemOnce();

  const onValuesChange = (values: Partial<Contact>) => {
    //remove notes from values
    delete values.notes;
    entry.update(values);
  };

  if (!contact) return null;

  return (
    <Stack p="sm">
      <EditContactForm
        initialValues={contact}
        onValuesChange={onValuesChange}
      />
    </Stack>
  );
};
