import { useCreateDealModel, useDealModels } from "@/hooks/useDeal";
import { Route } from "@/paths";
import { CustomCellRendererProps } from "@ag-grid-community/react";
import { Button, Group, Menu, Stack } from "@mantine/core";
import { useMemo } from "react";
import { BiPlus } from "react-icons/bi";
import { BsThreeDots } from "react-icons/bs";
import { LuFileEdit, LuFileSpreadsheet } from "react-icons/lu";
import { RiPushpin2Fill } from "react-icons/ri";
import { Link, generatePath } from "react-router-dom";

export const ModelRenderer = (props: CustomCellRendererProps) => {
  const isEditable = !!props.api.getGridOption("defaultColDef")?.editable;
  const modelIds = useMemo(
    () => props.node.data.modelIds,
    [props.node.data.modelIds],
  );
  const [models, loading, error] = useDealModels(modelIds);
  const [create] = useCreateDealModel();

  const sortedModels = models?.sort((a, b) => {
    return b.createdAt.toMillis() - a.createdAt.toMillis();
  });

  const pinnedModel = models?.find(
    (model) => model.id === props.node.data.pinnedModelId,
  );
  const editModelId = pinnedModel?.id || models?.[0]?.id;

  const createNewModel = async () => {
    const ref = await create(props.node.data.id);
    if (!ref) return;

    window.open(
      generatePath(Route.ModelTab, {
        modelId: ref.id,
        modelTabId: "historicals",
      }),
      "_blank",
      "noreferrer",
    );
  };

  if (loading) {
    return null;
  }

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  if (!models || (models || []).length === 0) {
    return (
      <Stack w={"100%"} h={"100%"} align="center" justify="center">
        <Button
          w={"100%"}
          size="xs"
          leftSection={<LuFileSpreadsheet size={16} />}
          onClick={createNewModel}
          fz={13}
          justify="space-between"
          rightSection={<LuFileSpreadsheet size={16} opacity={0} />}
          disabled={!isEditable}
        >
          Create Model
        </Button>
      </Stack>
    );
  }

  return (
    <Stack w={"100%"} h={"100%"} align="center" justify="center">
      <Button.Group w={"100%"} bg="white">
        <Button
          justify="space-between"
          w={"100%"}
          size="xs"
          variant="light"
          leftSection={<LuFileEdit size={16} />}
          autoContrast
          color="accent"
          component={Link}
          to={generatePath(Route.ModelTab, {
            modelId: editModelId,
            modelTabId: "historicals",
          })}
          fz={13}
        >
          {isEditable ? "Edit" : "View"} Model
        </Button>
        <Menu
          shadow="md"
          width={200}
          position="bottom-end"
          withArrow
          arrowPosition="center"
        >
          <Menu.Target>
            <Button
              size="xs"
              variant="filled"
              color="blue.1"
              autoContrast
              pl="xs"
              pr="xs"
              c={"blue.8"}
            >
              <BsThreeDots size={18} />
            </Button>
          </Menu.Target>
          <Menu.Dropdown>
            {sortedModels.map((model) => (
              <Menu.Item
                key={model.id}
                onClick={() => {
                  window.open(
                    generatePath(Route.ModelTab, {
                      modelId: model.id,
                      modelTabId: "historicals",
                    }),
                    "_blank",
                    "noreferrer",
                  );
                }}
              >
                <Group gap={"xs"} align="center" justify="space-between">
                  {model.name}
                  {model.id === props.node.data.pinnedModelId && (
                    <RiPushpin2Fill style={{ position: "relative", top: -2 }} />
                  )}
                </Group>
              </Menu.Item>
            ))}
            <Menu.Divider />
            <Menu.Item
              leftSection={<BiPlus />}
              onClick={createNewModel}
              disabled={!isEditable}
            >
              Create Model
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Button.Group>
    </Stack>
  );
};
