import { openAddContactModal } from "@/components/AddContactModal/AddContactModal";
import { EditContactForm } from "@/components/EditContactForm/EditContactForm";
import { Icons } from "@/components/Icons/Icons";
import { LayoutWorkstation } from "@/components/LayoutWorkstation/LayoutWorkStation";
import { TagsRenderer } from "@/components/TagInput/TagsRenderer";
import { Route } from "@/paths";
import { useContactDirectoryJobTitleTags } from "@/resources/contactDirectories";
import contactsResource, { Contact as ContactT } from "@/resources/contacts";
import { useDealEntry } from "@/resources/deals";
import { Tag } from "@/resources/tags";
import {
  ActionIcon,
  Button,
  Group,
  Stack,
  Table,
  Tooltip,
} from "@mantine/core";
import { modals } from "@mantine/modals";
import { arrayRemove } from "firebase/firestore";
import { CgComment } from "react-icons/cg";
import { Link, generatePath } from "react-router-dom";

export const Contacts = () => {
  const dealEntry = useDealEntry();
  const [deal] = dealEntry.useItem();
  const [jobTitleTags] = useContactDirectoryJobTitleTags();

  if (!deal) return null;

  const contactIds = deal.contactIds || [];

  return (
    <LayoutWorkstation.Panel label="Contacts" p={0}>
      {contactIds.length > 0 && (
        <Table.ScrollContainer minWidth={800}>
          <Table verticalSpacing="sm" horizontalSpacing={6} striped>
            <Table.Thead>
              <Table.Tr>
                <Table.Th px="sm">Name / Email</Table.Th>
                <Table.Th>Phone / Website</Table.Th>
                <Table.Th>Firm / Location </Table.Th>
                <Table.Th>Role</Table.Th>
                <Table.Th></Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {contactIds.map((contactId) => (
                <Contact
                  key={contactId}
                  contactId={contactId}
                  tagOptions={jobTitleTags || []}
                />
              ))}
            </Table.Tbody>
          </Table>
        </Table.ScrollContainer>
      )}
      <Stack gap={"xs"} p={"xs"}>
        <Button variant="outline" onClick={openAddContactModal}>
          Add Contact
        </Button>
      </Stack>
    </LayoutWorkstation.Panel>
  );
};

const Contact = ({
  contactId,
  tagOptions,
}: {
  contactId: string;
  tagOptions: Tag[];
}) => {
  const [contact] = contactsResource.entry(contactId).useItem();
  const dealEntry = useDealEntry();
  const [updateDeal] = dealEntry.useUpdate();

  const remove = async (contact: ContactT) => {
    updateDeal({
      contactIds: arrayRemove(contact.id) as unknown as string[],
    });
  };

  const openEditModal = (contact: ContactT) => {
    modals.open({
      title: "Add Contact",
      children: (
        <EditContactForm
          callToAction="Update Contact"
          initialValues={contact}
          onSubmit={async (newValues) => {
            await contactsResource.entry(contact.id).update(newValues);
            modals.closeAll();
          }}
        />
      ),
    });
  };

  if (!contact) return null;

  return (
    <>
      <Table.Tr>
        <Table.Td px="sm">
          <Group gap={"xs"}>
            <Stack gap={2}>
              <Group gap={3}>
                {contact.name}
                {contact.remarks && (
                  <Tooltip label={contact.remarks} inline>
                    <ActionIcon size={"xs"} variant="transparent">
                      <CgComment />
                    </ActionIcon>
                  </Tooltip>
                )}{" "}
              </Group>
              {contact.email}
            </Stack>
          </Group>
        </Table.Td>
        <Table.Td>
          {contact.email}
          <br />
          {contact.website}
        </Table.Td>
        <Table.Td>
          {contact.firm}
          <br />
          {contact.location}
        </Table.Td>
        <Table.Td maw={100}>
          <Group gap={4}>
            <TagsRenderer
              tagIds={contact.jobTitleIds}
              tagOptions={tagOptions}
            />
          </Group>
        </Table.Td>

        <Table.Td w={140}>
          <Group gap={"xs"}>
            <Tooltip label="View contact">
              <ActionIcon
                variant="light"
                size={"lg"}
                component={Link}
                to={generatePath(Route.Contact, { contactId: contact.id })}
              >
                <Icons.View />
              </ActionIcon>
            </Tooltip>
            <Tooltip label="Edit contact">
              <ActionIcon
                variant="light"
                size={"lg"}
                onClick={() => openEditModal(contact)}
              >
                <Icons.Edit />
              </ActionIcon>
            </Tooltip>
            <Tooltip label="Remove contact from deal">
              <ActionIcon
                variant="light"
                color="red"
                size={"lg"}
                onClick={() => remove(contact)}
              >
                <Icons.Remove />
              </ActionIcon>
            </Tooltip>
          </Group>
        </Table.Td>
      </Table.Tr>
    </>
  );
};
