import { useAuthContext } from "@/contexts/AuthProvider";
import { useSubscription } from "./useSubscriptions";

export const useIsPlanInactive = () => {
  const { user } = useAuthContext();
  const [subscription, loadingSubscription] = useSubscription();

  const userCreated = !user?.metadata.creationTime
    ? new Date()
    : new Date(user?.metadata.creationTime);

  // trial expires 10 days after user creation
  const isTrialExpired =
    new Date() > new Date(userCreated.getTime() + 10 * 24 * 60 * 60 * 1000);

  const activeSubscription = ["active", "trialing"].includes(
    subscription?.status || "",
  );

  let isSubscriptionInactive = !activeSubscription;
  if (loadingSubscription) isSubscriptionInactive = false;

  let isPlanInactive = isTrialExpired && isSubscriptionInactive;

  if (window.location.href.includes("trial-expired")) isPlanInactive = true;
  if (window.location.href.includes("subscription-expired"))
    isPlanInactive = true;

  const inactiveElementProps = {
    ...(isPlanInactive
      ? { style: { pointerEvents: "none", opacity: 0.3 } }
      : {}),
  } as Partial<React.HTMLAttributes<HTMLDivElement>>;

  // console.log({
  //   isPlanInactive,
  //   isTrialExpired,
  //   isSubscriptionInactive,
  //   loadingSubscription,
  // });

  return {
    isPlanInactive,
    isTrialExpired,
    isSubscriptionInactive,
    inactiveElementProps,
  } as const;
};
