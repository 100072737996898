import {
  Button,
  Card,
  Container,
  Group,
  Select,
  Stack,
  Text,
  Textarea,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useState } from "react";
import { BsFillEnvelopeCheckFill } from "react-icons/bs";
import { LayoutPage } from "../LayoutPage/LayoutPage";

export const PageContactSales = () => {
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const form = useForm({
    mode: "uncontrolled",
    initialValues: {
      email: "",
      fullName: "",
      phoneNumber: "", //optional
      typeOfOrg: "",
      message: "", //optional
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
    },
  });

  const handleSubmit = (values: typeof form.values) => {
    console.log(values);
    setLoading(true);
    fetch(
      "https://us-central1-eta-iq-web.cloudfunctions.net/onHandleContactUsForm",
      {
        method: "POST",
        body: JSON.stringify(values),
      },
    )
      .then(() => {
        setLoading(false);
        setHasSubmitted(true);
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoading(false);
      });
  };

  return (
    <LayoutPage bg={"gray.0"}>
      <Container size={"sm"} py={"xl"}>
        <Stack align="center" gap="lg">
          <Text fw="bold" size="xl" fz={32} ta={"center"}>
            Contact Sales
          </Text>
          <Text ta={"center"} size="lg" maw={560}>
            We are happy to answer your questions about licensing our products.
            We typically responds in just a few hours.
          </Text>
          {
            // Show success message after form submission
            hasSubmitted && (
              <Card shadow="xs" padding="lg" w={"100%"}>
                <Stack gap="lg" align="center">
                  <BsFillEnvelopeCheckFill size={200} />
                  <Text ta={"center"} size="lg" fw="bold" maw={260}>
                    Thank you for your interest! We will contact you shortly.
                  </Text>
                </Stack>
              </Card>
            )
          }

          {!hasSubmitted && (
            <Card shadow="xs" padding="lg" w={"100%"}>
              <form onSubmit={form.onSubmit(handleSubmit)}>
                <Stack>
                  <TextInput
                    withAsterisk
                    label="Name"
                    placeholder=""
                    key={form.key("fullName")}
                    {...form.getInputProps("fullName")}
                  />
                  <TextInput
                    withAsterisk
                    label="Email"
                    placeholder="your@email.com"
                    key={form.key("email")}
                    {...form.getInputProps("email")}
                  />
                  <TextInput
                    label="Phone number (optional)"
                    placeholder="optional"
                    key={form.key("phoneNumber")}
                    {...form.getInputProps("phoneNumber")}
                  />

                  <Select
                    withAsterisk
                    label="Type of organization"
                    data={[
                      "Partnered Search",
                      "Search with interns",
                      "Search with accelerator / investment platform",
                      "Bootcamp",
                      "University / College",
                      "Other",
                    ]}
                    placeholder="Select type of organization"
                    key={form.key("typeOfOrg")}
                    {...form.getInputProps("typeOfOrg")}
                  />

                  <Textarea
                    label="Message (optional)"
                    placeholder="Your message"
                    key={form.key("message")}
                    {...form.getInputProps("message")}
                  />

                  <Group justify="center">
                    <Button type="submit" size="lg" w={300} loading={loading}>
                      Submit
                    </Button>
                  </Group>
                </Stack>
              </form>
            </Card>
          )}
        </Stack>
      </Container>
    </LayoutPage>
  );
};
