import { useAuthContext } from "@/contexts/AuthProvider";
import { firestore } from "@/firebase";
import { collection, doc } from "firebase/firestore";
import { useDocument } from "react-firebase-hooks/firestore";

type UserAccount = {
  customerId: string;
};

export const useUserAccount = () => {
  const { user } = useAuthContext();

  const [snapshot, loading, error] = useDocument(
    user?.uid ? doc(collection(firestore, `userAccount`), user?.uid) : null,
  );

  const result = !snapshot
    ? undefined
    : snapshot?.exists()
      ? (snapshot.data() as UserAccount)
      : null;

  return [
    result,
    !user?.uid || snapshot === undefined || loading,
    error,
  ] as const;
};
