import { Icons } from "@/components/Icons/Icons";
import { Resource } from "@/resources/_resource";
import { ActionIcon, Text } from "@mantine/core";
import { modals } from "@mantine/modals";

export const TrashButton = ({
  resource,
  name,
  id,
}: {
  resource: Resource;
  name: string;
  id: string;
}) => {
  const openDeleteModal = async () => {
    await modals.openConfirmModal({
      title: `Delete a ${resource.name}`,

      children: (
        <Text size="md">
          Are you sure you want to delete "{name}"? This action is destructive
          and can't be undone.
        </Text>
      ),
      labels: {
        confirm: `Delete "${name}"`,
        cancel: "Cancel",
      },
      confirmProps: { color: "red" },
      onConfirm: async () => {
        resource.remove(id);
      },
    });
  };

  return (
    <ActionIcon variant={"light"} onClick={openDeleteModal}>
      <Icons.Delete />
    </ActionIcon>
  );
};
