import img1 from "@/assets/screenshot-clip-1.webp";
import img2 from "@/assets/screenshot-clip-2.webp";
import img3 from "@/assets/screenshot-clip-3.webp";
import img4 from "@/assets/screenshot-clip-4.webp";
import {
  Container,
  Paper,
  SimpleGrid,
  Stack,
  Text,
  ThemeIcon,
} from "@mantine/core";
import {
  TbAddressBook,
  TbChartDots,
  TbListDetails,
  TbPigMoney,
} from "react-icons/tb";

const features = [
  {
    title: "Financial Modeling",
    description:
      "Start with basics, build up to a comprehensive cash flow model. Easily share it with lenders & investors.",
    imgSrc: img2,
    icon: TbChartDots,
  },
  {
    title: "Deal Tracker",
    description:
      "Manage all your prospective deals in a centralized dashboard. Track progress, spot trends, and stay organized.",
    imgSrc: img1,
    icon: TbListDetails,
  },
  {
    title: "Contact Management",
    description:
      "Keep all your contacts in one place — easily find, update, and link them to deals.",
    imgSrc: img3,
    icon: TbAddressBook,
  },
  {
    title: "Investor Returns",
    description:
      "Project investor returns and experiment with terms to structure the right deal for equity partners.",
    imgSrc: img4,
    icon: TbPigMoney,
  },
];

export function FeaturesTitle() {
  return (
    <section
      style={{
        backgroundColor: "#F9F5F1",
      }}
    >
      <Container size="lg">
        <SimpleGrid cols={{ base: 1, sm: 4 }}>
          {features.map((feature) => {
            return (
              <Paper
                withBorder
                key={feature.title}
                p={"lg"}
                pos={"relative"}
                top={-20}
                radius={0}
                bd={"none"}
              >
                <Stack>
                  <ThemeIcon size={42} variant="transparent">
                    <feature.icon size={42} />
                  </ThemeIcon>
                  <Text lh={1} fw={"bold"} size="xl">
                    {feature.title}
                  </Text>
                  <Text lh={1.4}>{feature.description}</Text>
                </Stack>
              </Paper>
            );
          })}
        </SimpleGrid>
      </Container>
    </section>
  );
}
