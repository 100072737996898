import { useAuthContext } from "@/contexts/AuthProvider";
import { Model } from "@/resources/models";
import { doc, setDoc } from "firebase/firestore";
import { atom, useSetAtom } from "jotai";
import { useState } from "react";
import { useDocument, useDocumentOnce } from "react-firebase-hooks/firestore";
import { useParams } from "react-router-dom";
import { useIsPlanInactive } from "./useIsPlanInactive";
import { collectionRef } from "./useModels";

export const useModelRef = (modelId?: string) => {
  const params = useParams();

  const id = modelId || params.modelId;

  return doc(collectionRef, id);
};

export const useModel = (modelId?: string) => {
  const modelRef = useModelRef(modelId);

  const [model, loading, error] = useDocument(modelRef);

  return [
    !model
      ? undefined
      : ({
          ...model?.data(),
          id: model?.id,
        } as Model),
    loading,
    error,
  ] as const;
};

export const useModelOnce = (modelId?: string) => {
  const modelRef = useModelRef(modelId);
  const [model, loading, error] = useDocumentOnce(modelRef);

  return [
    !model
      ? undefined
      : ({
          ...model?.data(),
          id: model?.id,
        } as Model),
    loading,
    error,
  ] as const;
};

export const useUpdate = () => {
  const ref = useModelRef();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const update = async (model: Partial<Model>) => {
    setLoading(true);
    setError(null);
    try {
      await setDoc(ref, model, { merge: true });
    } catch (error: unknown) {
      setError(error as Error);
    }
    setLoading(false);
  };

  return [update, loading, error] as const;
};

export const useIsUserModelCreator = () => {
  const { user } = useAuthContext();
  const [model] = useModel();

  return [model?.creatorId === user?.uid] as const;
};

export const useIsModelReadOnly = () => {
  const [isUserModelCreator] = useIsUserModelCreator();
  const { isPlanInactive } = useIsPlanInactive();
  const setIsModelReadOnlyFromHookAtom = useSetAtom(
    isModelReadOnlyFromHookAtom,
  );

  const result = !isUserModelCreator || isPlanInactive;

  setIsModelReadOnlyFromHookAtom(result);

  return [result] as const;
};

export const isModelReadOnlyFromHookAtom = atom(false);
