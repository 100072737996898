import { useAuthContext } from "@/contexts/AuthProvider";
import { useUpdateUserProfile } from "@/hooks/useUserProile";
import { Button, Card, Stack, TextInput } from "@mantine/core";
import { useState } from "react";

export const UserInfo = () => {
  const { user } = useAuthContext();
  const [displayName, setDisplayName] = useState(user?.displayName || "");
  const [update, loading] = useUpdateUserProfile();

  const handleUpdateProfile = async () => {
    if (!user) return;
    await update(displayName);
  };

  return (
    <Card withBorder radius={0} h={"100%"}>
      <Stack>
        <TextInput
          size="lg"
          label="Name"
          value={displayName}
          onChange={(e) => setDisplayName(e.currentTarget.value)}
        />
        <TextInput size="lg" label="Email" value={user?.email || ""} disabled />
        <Button loading={loading} onClick={handleUpdateProfile}>
          Update Profile
        </Button>
      </Stack>
    </Card>
  );
};
