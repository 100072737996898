import { useBuildEntryIfNotExist } from "@/resources/_resource";
import { useContactDirectoryEntry } from "@/resources/contactDirectories";
import { Loader } from "@mantine/core";
import { Main } from "./Main/Main";
import { Sidebar } from "./Sidebar/Sidebar";

export const PageContacts = () => {
  const entry = useContactDirectoryEntry();
  useBuildEntryIfNotExist(entry!);
  const [item] = entry!.useItem();
  if (!item)
    return (
      <Loader
        size={50}
        pos={"absolute"}
        left={"50%"}
        top={"40%"}
        opacity={0.2}
      />
    );

  return (
    <>
      <Sidebar />
      <Main />
    </>
  );
};
