import { TagInputEditor } from "@/components/TagInput/TagInputEditor";
import { useContactDirectoryJobTitleTags } from "@/resources/contactDirectories";
import { CustomCellEditorProps } from "@ag-grid-community/react";

export const JobTitleTagEditor = ({
  value,
  onValueChange,
  stopEditing,
}: CustomCellEditorProps) => {
  const [tagOptions] = useContactDirectoryJobTitleTags();

  return (
    <TagInputEditor
      value={value}
      onValueChange={onValueChange}
      stopEditing={stopEditing}
      tagOptions={tagOptions || []}
      radius={0}
      size="lg"
      autoFocus={true}
    />
  );
};
