import { auth } from "@/firebase";
import dealsResource from "@/resources/deals";
import { useEffect } from "react";
import { syncUserProfileToAuth } from "./useUserProile";

let hasRunOnce = false;

export const useInitializeAccount = () => {
  useEffect(() => {
    if (!auth.currentUser) return;

    syncUserProfileToAuth();

    const asynRun = async () => {
      if (hasRunOnce) return;
      hasRunOnce = true;

      const deal = await dealsResource.create({ companyName: "My First Deal" });
      console.log(deal);
    };

    asynRun();
  }, []);
};
