import videoImg from "@/assets/video-card.webp";
import { Box, Group } from "@mantine/core";
import LiteYouTubeEmbed from "react-lite-youtube-embed";

export const Video = () => {
  return (
    <Group
      justify="center"
      align="center"
      mb={"-19dvh"}
      style={{
        //grandient
        background:
          "linear-gradient(180deg, var(--mantine-color-brand-8)0%, var(--mantine-color-brand-9) 100%)",
      }}
    >
      <Box
        pos="relative"
        top={"-19dvh"}
        pb={60}
        pt={"xl"}
        px={"xl"}
        w={"100%"}
        maw={1210}
      >
        <Box
          style={{
            boxShadow: "0px 0px 150px 0px rgba(256, 256, 256, 0.1)",
            borderRadius: 12,
            overflow: "hidden",
          }}
        >
          <LiteYouTubeEmbed
            id="JCUhIvTImYQ"
            title="What is ETA IQ?"
            thumbnail={videoImg}
            noCookie={true}
            rel="0"
            //playlist={true}
            params="rel=0&modestbranding=1&showinfo=0&autohide=1&controls=1"
          />
        </Box>
      </Box>
    </Group>
  );
};
