import { LayoutPage } from "@/components/LayoutPage/LayoutPage";
import { Container, Grid, Stack, Text } from "@mantine/core";
import { Actions } from "./Actions";
import { Subscriptions } from "./Subscriptions";
import { UserInfo } from "./UserInfo";

export const PageProfile = () => {
  return (
    <LayoutPage bg="styledBackgrounds.6">
      <Container my={"xl"}>
        <Stack>
          <Text size="xl" fw={"bold"}>
            Billing
          </Text>
          <Subscriptions />

          <Text size="xl" fw={"bold"}>
            Profile
          </Text>
          <Grid>
            <Grid.Col span={{ base: 12, md: 8 }}>
              <UserInfo />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 4 }}>
              <Actions />
            </Grid.Col>
          </Grid>
        </Stack>
      </Container>
    </LayoutPage>
  );
};
