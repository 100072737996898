import { LayoutWorkstation } from "@/components/LayoutWorkstation/LayoutWorkStation";
import { LayoutTrialEndWarning } from "../LayoutTrialEndWarning/LayoutTrialEndWarning";
import { Header } from "./Main/Header";
import { Main } from "./Main/Main";
import { Sidebar } from "./Sidebar/Sidebar";

export const PageDeal = () => {
  return (
    <LayoutTrialEndWarning>
      <LayoutWorkstation>
        <Sidebar />
        <LayoutWorkstation.Main>
          <Header />
          <Main />
        </LayoutWorkstation.Main>
      </LayoutWorkstation>
    </LayoutTrialEndWarning>
  );
};
