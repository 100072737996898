import { arrayRemove, arrayUnion, setDoc } from "firebase/firestore";
import { useState } from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { useModelRef } from "./useModel";

export const useModelSharingAllowedEmails = (modelId: string) => {
  const modelRef = useModelRef(modelId);
  const [model, loading, error] = useDocumentData(modelRef);

  const allowedEmails = (model?.allowedEmails || []).filter(
    (email: string) => email !== "public",
  ) as string[];

  return [allowedEmails, loading, error] as const;
};

export const useModelSharingIsPublic = (modelId: string) => {
  const modelRef = useModelRef(modelId);
  const [model, loading, error] = useDocumentData(modelRef);

  const isPublic = model?.allowedEmails?.includes("public") ?? false;

  return [isPublic, loading, error] as const;
};

export const useModelSharingTogglePublic = (modelId: string) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const modelRef = useModelRef(modelId);
  const [isPublic] = useModelSharingIsPublic(modelId);

  const setPublic = async () => {
    setLoading(true);

    try {
      if (isPublic) {
        setDoc(
          modelRef,
          {
            allowedEmails: arrayRemove("public"),
          },
          { merge: true },
        );
      } else {
        setDoc(
          modelRef,
          {
            allowedEmails: arrayUnion("public"),
          },
          { merge: true },
        );
      }
    } catch (error: unknown) {
      setError(error as Error);
    }
    setLoading(false);
  };

  return [setPublic, loading, error] as const;
};

export const useModelSharingAddEmail = (modelId: string) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const modelRef = useModelRef(modelId);

  const addEmail = async (email: string) => {
    setLoading(true);
    try {
      setDoc(
        modelRef,
        {
          allowedEmails: arrayUnion(email),
        },
        { merge: true },
      );
    } catch (error: unknown) {
      setError(error as Error);
    }
    setLoading(false);
  };

  return [addEmail, loading, error] as const;
};

export const useModelSharingRemoveEmail = (modelId: string) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const modelRef = useModelRef(modelId);

  const removeEmail = async (email: string) => {
    setLoading(true);
    try {
      setDoc(
        modelRef,
        {
          allowedEmails: arrayRemove(email),
        },
        { merge: true },
      );
    } catch (error: unknown) {
      setError(error as Error);
    }
    setLoading(false);
  };

  return [removeEmail, loading, error] as const;
};
