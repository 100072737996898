import { ActionButtonGroup } from "@/components/AppGrid/ActionButtons/ActionButtonGroup";
import { EditButton } from "@/components/AppGrid/ActionButtons/EditButton";
import { TrashButton } from "@/components/AppGrid/ActionButtons/TrashButton";
import { Route } from "@/paths";
import contactsResource from "@/resources/contacts";
import { CustomCellRendererProps } from "@ag-grid-community/react";
import { generatePath } from "react-router-dom";

export const ActionRenderer = (props: CustomCellRendererProps) => {
  return (
    <ActionButtonGroup>
      <EditButton
        path={generatePath(Route.Contact, { contactId: props.data.id })}
      />
      <TrashButton
        resource={contactsResource}
        name={props.data.name}
        id={props.data.id}
      />
    </ActionButtonGroup>
  );
};
