import { AppGridCreateItemButton } from "@/components/AppGrid/AppGridCreateItemButton/AppGridCreateItemButton";
import { AppGridFilter } from "@/components/AppGrid/AppGridFilter/AppGridFilter";
import { AppGridSearch } from "@/components/AppGrid/AppGridSearch/AppGridSearch";
import { AppTagFilter } from "@/components/AppGrid/AppTagFilter/AppTagFilter";
import { LayoutWorkstation } from "@/components/LayoutWorkstation/LayoutWorkStation";
import { useContactDirectoryEntry } from "@/resources/contactDirectories";
import resource from "@/resources/contacts";
import { Stack } from "@mantine/core";
import { colDefs } from "../colDefs";

export const Sidebar = () => {
  const entry = useContactDirectoryEntry();

  return (
    <LayoutWorkstation.Sidebar size={"sm"} h={"100%"}>
      <Stack p="sm" h={"100%"} justify="space-between">
        <Stack>
          <AppGridCreateItemButton resource={resource} />
          <AppGridSearch />

          <AppTagFilter
            label={"Role"}
            entry={entry}
            resourceProp={"jobTitleTags"}
            columnId="jobTitleIds"
          />
        </Stack>

        <AppGridFilter colDefs={colDefs} />
      </Stack>
    </LayoutWorkstation.Sidebar>
  );
};
