import { ColDef } from "@ag-grid-community/core";
import { Button, Popover } from "@mantine/core";
import { LuTableProperties } from "react-icons/lu";
import { ColumnToggles } from "./ColumnToggles";

export const AppGridFilter = ({ colDefs }: { colDefs: ColDef[] }) => {
  return (
    <Popover width={420} position="right" withArrow shadow="md">
      <Popover.Target>
        <Button
          variant="outline"
          color="gray"
          justify="space-between"
          leftSection={<LuTableProperties size={18} />}
          rightSection={<LuTableProperties size={18} opacity={0} />}
          size="md"
        >
          Show / Hide Columns
        </Button>
      </Popover.Target>
      <Popover.Dropdown>
        <ColumnToggles colDefs={colDefs} />
      </Popover.Dropdown>
    </Popover>
  );
};
