import { AppGridCreateItemButton } from "@/components/AppGrid/AppGridCreateItemButton/AppGridCreateItemButton";
import { AppGridFilter } from "@/components/AppGrid/AppGridFilter/AppGridFilter";
import { AppGridSearch } from "@/components/AppGrid/AppGridSearch/AppGridSearch";
import { AppTagFilter } from "@/components/AppGrid/AppTagFilter/AppTagFilter";
import { LayoutWorkstation } from "@/components/LayoutWorkstation/LayoutWorkStation";
import { useDealDirectoryEntry } from "@/resources/dealDirectories";
import resource from "@/resources/deals";
import { Stack } from "@mantine/core";
import { colDefs } from "../colDefs";

export const Sidebar = () => {
  const entry = useDealDirectoryEntry();

  return (
    <LayoutWorkstation.Sidebar size={"sm"} h={"100%"}>
      <Stack p="sm" h={"100%"} justify="space-between">
        <Stack>
          <AppGridCreateItemButton
            resource={resource}
            applySortOnCreate={{
              state: [{ colId: "categoryId", sort: "asc" }],
              defaultState: { sort: null },
            }}
          />
          <AppGridSearch />

          <AppTagFilter
            label={"Status"}
            entry={entry!}
            resourceProp={"categories"}
            columnId="categoryId"
          />
        </Stack>

        <AppGridFilter colDefs={colDefs} />
      </Stack>
    </LayoutWorkstation.Sidebar>
  );
};
