import { Group, Stack } from "@mantine/core";
import { Outlet } from "react-router-dom";
import { LayoutTrialEndWarning } from "../LayoutTrialEndWarning/LayoutTrialEndWarning";
import { defaultHeaderHeight } from "../LayoutWorkstation/Header";
import { LayoutWorkstation } from "../LayoutWorkstation/LayoutWorkStation";
import { NotAvailableOnMobile } from "../NotAvailableOnMobile/NotAvailableOnMobile";
import { Header } from "./Header";

export const PageApp = () => {
  return (
    <>
      <LayoutTrialEndWarning>
        <LayoutWorkstation>
          <Stack gap={0} w={"100%"} h={"100%"}>
            <Header />
            <Group
              h={`calc(100dvh - ${defaultHeaderHeight}px)`}
              gap={0}
              miw={0}
              bg={"gray.3"}
              style={{ overflow: "hidden" }}
            >
              <Outlet />
            </Group>
          </Stack>
        </LayoutWorkstation>
      </LayoutTrialEndWarning>
      <NotAvailableOnMobile />
    </>
  );
};
