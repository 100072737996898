import { ResourceEntry } from "@/resources/_resource";
import { fixLegacyCategoryIdValue } from "@/resources/dealDirectories";
import { getPaletteById, Tag } from "@/resources/tags";
import { CustomCellRendererProps } from "@ag-grid-community/react";
import { Badge } from "@mantine/core";

export const TagRenderer = ({
  value,
  getEntry,
  resourceProp,
}: CustomCellRendererProps & {
  getEntry: () => ResourceEntry;
  resourceProp: string;
}) => {
  const [item] = getEntry().useItem();
  const tags = item?.[
    resourceProp as unknown as keyof typeof item
  ] as unknown as Tag[];

  if (!item) return null;

  const tagIds = fixLegacyCategoryIdValue(value);

  return (
    <div
      style={{
        display: "flex",
        height: 40,
        //overflow: "hidden",
        alignItems: "center",
      }}
    >
      {tagIds.map((tagId: string) => {
        const tag = tags?.find((tag) => tag.id === tagId);
        const color = getPaletteById(tag?.paletteId || "");
        return (
          <Badge
            display={!tag ? "none" : undefined}
            key={tagId}
            size="lg"
            color={color?.backgroundColor}
            variant="filled"
            c={color?.fontColor}
            tt={"none"}
            mr={4}
          >
            {tag?.label}
          </Badge>
        );
      })}
    </div>
  );
};
