import { useModelDeal } from "@/hooks/useModelDeal";
import { Route } from "@/paths";
import { Model } from "@/resources/models";
import { CustomCellRendererProps } from "@ag-grid-community/react";
import { Anchor } from "@mantine/core";

export const DealRenderer = (props: CustomCellRendererProps) => {
  const model = props.data as Model;
  const [deal, loading] = useModelDeal(model.id);

  if (!deal || loading) return null;

  return (
    <>
      <Anchor
        inherit
        underline="always"
        href={Route.Deal.replace(":dealId", deal?.id || "missing-deal-id")}
      >
        {deal?.companyName || "Untitled Deal"}
      </Anchor>
    </>
  );
};
