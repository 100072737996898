export type Tag = {
  id: string;
  label: string;
  paletteId: string;
};

const palettes = [
  {
    id: "gray",
    backgroundColor: "#BDBDBD",
    fontColor: "#424242",
  },
  {
    id: "lipstick",
    backgroundColor: "#D81159",
    fontColor: "#FAE1E4",
  },
  {
    id: "coral",
    backgroundColor: "#FF6B6B",
    fontColor: "#802525",
  },
  {
    id: "peach",
    backgroundColor: "#F9A07A",
    fontColor: "#803D24",
  },
  {
    id: "orange",
    backgroundColor: "#FFA500",
    fontColor: "#804D00",
  },
  {
    id: "mango",
    backgroundColor: "#FFC107",
    fontColor: "#806600",
  },
  {
    id: "sunnyYellow",
    backgroundColor: "#F6D06F",
    fontColor: "#7A5A1B",
  },
  {
    id: "lemonYellow",
    backgroundColor: "#FFEB3B",
    fontColor: "#7F6B00",
  },
  {
    id: "limeGreen",
    backgroundColor: "#B4E197",
    fontColor: "#2F3B1A",
  },
  {
    id: "oliveGreen",
    backgroundColor: "#6E7E56",
    fontColor: "#323A1D",
  },
  {
    id: "sageGreen",
    backgroundColor: "#5E8A71",
    fontColor: "#254231",
  },

  {
    id: "teal",
    backgroundColor: "#4DB6AC",
    fontColor: "#16504B",
  },
  {
    id: "royalBlue",
    backgroundColor: "#00539C",
    fontColor: "#CEC8FB",
  },
  {
    id: "skyBlue",
    backgroundColor: "#7FDBFF",
    fontColor: "#2E7EBD",
  },
  {
    id: "babyBlue",
    backgroundColor: "#A6E3E9",
    fontColor: "#3E8A8F",
  },
  {
    id: "lavender",
    backgroundColor: "#A998F4",
    fontColor: "#382C91",
  },
  {
    id: "pastelPink",
    backgroundColor: "#FDC5F5",
    fontColor: "#8F508E",
  },
  {
    id: "lightPink",
    backgroundColor: "#FF99C8",
    fontColor: "#9F2F69",
  },
  {
    id: "peachPink",
    backgroundColor: "#F79C99",
    fontColor: "#9E3735",
  },
  {
    id: "burgundy",
    backgroundColor: "#3C1D32",
    fontColor: "#A06A8E",
  },
  {
    id: "forestGreen",
    backgroundColor: "#2D4844",
    fontColor: "#8AB2AE",
  },
  {
    id: "midnightBlue",
    backgroundColor: "#2E2A54",
    fontColor: "#CEC8FB",
  },
] as const;

export const getPaletteById = (id: string) => {
  return palettes.find((p) => p.id === id);
};

export const getRandomPaletteId = () => {
  return palettes[Math.floor(Math.random() * palettes.length)].id;
};

export const getAllPalettes = () => {
  return [...palettes];
};

export const getPaletteByColor = (color: string) => {
  return palettes.find(
    (p) => p.backgroundColor.toLowerCase() === color.toLowerCase(),
  );
};
