import { useIsModelReadOnly } from "@/hooks/useModel";
import { Group, SegmentedControl, SegmentedControlProps } from "@mantine/core";

export const InputDollarPercentSwitch = ({
  value,
  onChange,
  ...props
}: Omit<SegmentedControlProps, "value" | "onChange" | "data"> & {
  value: boolean;
  onChange: (value: string) => void;
}) => {
  const [isModelShared] = useIsModelReadOnly();

  return (
    <Group justify="center">
      <SegmentedControl
        disabled={isModelShared}
        {...props}
        //classNames={classes}
        fullWidth
        w={"100%"}
        data={[
          { label: "Dollar ($)", value: "dollar" },
          { label: "Percent (%)", value: "percent" },
        ]}
        value={value ? "dollar" : "percent"}
        onChange={onChange}
        color="accent.6"
      />
    </Group>
  );
};
