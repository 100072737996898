import {
  appGridApiAtom,
  appRowDataUpdatedAtAtom,
} from "@/components/AppGrid/AppGrid";
import { LayoutWorkstation } from "@/components/LayoutWorkstation/LayoutWorkStation";
import { ResourceEntry } from "@/resources/_resource";
import { fixLegacyCategoryIdValue } from "@/resources/dealDirectories";
import { Tag, getPaletteById } from "@/resources/tags";
import {
  ActionIcon,
  Badge,
  Group,
  Popover,
  ScrollArea,
  SegmentedControl,
  Stack,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useAtomValue } from "jotai";
import { useEffect, useMemo, useState } from "react";
import { BiCog } from "react-icons/bi";
import { EditTagList } from "./EditTagList";

export const AppTagFilter = ({
  entry,
  resourceProp,
  columnId,
  label = "Status",
}: {
  entry: ResourceEntry;
  resourceProp: string;
  columnId: string;
  label?: string;
}) => {
  const api = useAtomValue(appGridApiAtom);
  const [item] = entry.useItem();
  const [activeTagId, setActiveTagId] = useState("all");
  const resourceTags = item?.[
    resourceProp as unknown as keyof typeof item
  ] as unknown as Tag[];
  const tags = [{ id: "all", label: "All" }, ...(resourceTags || [])] as Tag[];
  const activeTag = tags.find((tag) => tag.id === activeTagId);
  const editTagsPopoverDisclosure = useDisclosure(false);
  const appRowDataUpdatedAt = useAtomValue(appRowDataUpdatedAtAtom);

  const onChange = (tagId: string) => {
    setActiveTagId(tagId);
  };

  useEffect(() => {
    api
      ?.setColumnFilterModel(
        columnId,
        activeTagId === "all"
          ? null
          : {
              filterType: "text",
              type: "contains",
              filter: activeTagId,
            },
      )
      ?.then(() => {
        api?.onFilterChanged();
      });
  }, [activeTagId]);

  const tagCounts = useMemo(() => {
    const countMap = new Map<string, number>();

    api?.forEachNode((node) => {
      const ids = fixLegacyCategoryIdValue(node.data[columnId]);
      ids.forEach((id) => {
        countMap.set(id, (countMap.get(id) || 0) + 1);
        countMap.set("all", (countMap.get("all") || 0) + 1);
      });
    });

    return countMap;
  }, [appRowDataUpdatedAt]);

  if (!item) return null;

  return (
    <Stack>
      <LayoutWorkstation.Sidebar.Title
        rightSection={
          <Popover
            width={240}
            position="bottom-end"
            withArrow
            shadow="md"
            opened={editTagsPopoverDisclosure[0]}
            onChange={() => editTagsPopoverDisclosure[1].toggle()}
          >
            <Popover.Target>
              <ActionIcon
                size={28}
                variant="transparent"
                color="gray"
                onClick={() => editTagsPopoverDisclosure[1].toggle()}
              >
                <BiCog size={18} />
              </ActionIcon>
            </Popover.Target>
            <Popover.Dropdown>
              <EditTagList
                label={label}
                resourceProp={resourceProp}
                initTags={resourceTags}
                entry={entry}
                onUpdateComplete={() => editTagsPopoverDisclosure[1].close()}
              />
            </Popover.Dropdown>
          </Popover>
        }
      >
        {label}
      </LayoutWorkstation.Sidebar.Title>
      <ScrollArea mah={"34dvh"} mih={200}>
        <SegmentedControl
          autoContrast
          color={
            getPaletteById(activeTag?.paletteId || "gray")?.backgroundColor
          }
          size="md"
          m={0}
          p={0}
          orientation="vertical"
          fullWidth
          withItemsBorders={false}
          onChange={onChange}
          data={tags.map((tag) => {
            return {
              ...tag,
              value: tag.id,
              label: (
                <Group justify="space-between">
                  <div>{tag.label}</div>
                  <Group gap={0}>
                    <Badge
                      fz={12}
                      size="md"
                      variant="filled"
                      miw={36}
                      color="gray.6"
                      style={{
                        color: getPaletteById(tag.paletteId)?.fontColor,
                        backgroundColor: getPaletteById(tag.paletteId)
                          ?.backgroundColor,
                      }}
                    >
                      {tagCounts.get(tag.id) || 0}
                    </Badge>
                  </Group>
                </Group>
              ),
            };
          })}
        />
      </ScrollArea>
    </Stack>
  );
};
