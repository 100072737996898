import { Icons } from "@/components/Icons/Icons";
import { ActionIcon } from "@mantine/core";
import { Link } from "react-router-dom";

export const EditButton = ({ path }: { path: string }) => {
  return (
    <ActionIcon variant={"light"} component={Link} to={path}>
      <Icons.Edit />
    </ActionIcon>
  );
};
