import { Group, Stack, Text } from "@mantine/core";

export const SchoolBanner = () => {
  return (
    <Stack gap={40} align="center" mb={90}>
      <Text fw={"bold"} size="xl" fz={28} ta={"center"}>
        Trusted by seachers at top business schools worldwide
      </Text>
      <Group gap={"4dvw"} justify="center" px={"4dvw"}>
        <img loading="lazy" src="/t3.png" alt="School Logo" height={42} />
        <img loading="lazy" src="/t1.png" alt="School Logo" height={42} />
        <img loading="lazy" src="/t4.png" alt="School Logo" height={42} />
        <img loading="lazy" src="/t0.png" alt="School Logo" height={42} />
        <img loading="lazy" src="/t5.png" alt="School Logo" height={42} />
        <img loading="lazy" src="/t6.png" alt="School Logo" height={42} />
        <img loading="lazy" src="/t2.png" alt="School Logo" height={42} />
      </Group>
    </Stack>
  );
};
