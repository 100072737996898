import { dealDocumentAtom } from "@/atoms/deal";
import {
  useCloneDealModel,
  useCreateDealModel,
  useDealModels,
  useRemoveDealModel,
} from "@/hooks/useDeal";
import { Route } from "@/paths";
import { Model } from "@/resources/models";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import {
  ActionIcon,
  Group,
  Menu,
  ScrollArea,
  Stack,
  Text,
  ThemeIcon,
  Tooltip,
  UnstyledButton,
} from "@mantine/core";
import { modals } from "@mantine/modals";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { BiPlusCircle } from "react-icons/bi";
import { BsFileEarmarkSpreadsheetFill, BsThreeDots } from "react-icons/bs";
import { LiaClone, LiaTrashAltSolid } from "react-icons/lia";
import { RiPushpin2Fill, RiPushpinLine } from "react-icons/ri";
import { generatePath } from "react-router-dom";

export function ModelsList() {
  const [parent] = useAutoAnimate(/* optional config */);
  const [[deal], setDeal] = useAtom(dealDocumentAtom);
  const [models, loadingDealModels] = useDealModels(deal?.modelIds || []);
  const [create] = useCreateDealModel();
  const [remove] = useRemoveDealModel();
  const [clone] = useCloneDealModel();
  const [listedModels, setListedModels] = useState<Model[]>([]);

  useEffect(() => {
    if (loadingDealModels) return;
    setListedModels(models || []);
  }, [loadingDealModels, models]);

  const orderedModels = listedModels?.sort((a, b) => {
    // Check if either model is pinned
    const aPinned = deal?.pinnedModelId === a.id ? -1 : 0;
    const bPinned = deal?.pinnedModelId === b.id ? -1 : 0;

    // If both are pinned or neither is pinned, sort by createdAt
    if (aPinned === bPinned) {
      return b.createdAt.toMillis() - a.createdAt.toMillis();
    }

    // Otherwise, sort by pinned status
    return aPinned - bPinned;
  });

  const createNewModel = async () => {
    const ref = await create(deal!.id);
    if (!ref) return;

    window.open(
      generatePath(Route.ModelTab, {
        modelId: ref.id,
        modelTabId: "historicals",
      }),
      "_blank",
      "noreferrer",
    );
  };

  const confirmDeleteModal = (model: Model) => {
    modals.openConfirmModal({
      title: "Please confirm your action",
      children: (
        <Text size="sm">
          Are you sure you want to delete the model{" "}
          <strong>"{model.name}"</strong>? This cannot be undone.
        </Text>
      ),
      labels: { confirm: "Delete Model", cancel: "Cancel" },
      confirmProps: { color: "red" },
      //onCancel: () => console.log("Cancel"),
      onConfirm: () => remove(deal!.id, model.id),
    });
  };

  return (
    <Stack>
      <Group justify="space-between">
        <Text size="sm" fw={600}>
          Models
        </Text>
        <Tooltip label="Create New Model" withArrow>
          <ActionIcon
            variant="transparent"
            size={"sm"}
            onClick={createNewModel}
          >
            <BiPlusCircle size={"100%"} />
          </ActionIcon>
        </Tooltip>
      </Group>
      <ScrollArea mah={300} offsetScrollbars={(models?.length || 0) > 4}>
        <Stack gap={"xs"} ref={parent}>
          {orderedModels?.map((model) => (
            <UnstyledButton
              bg={"white"}
              style={{
                border: "1px solid var(--mantine-color-gray-4)",
              }}
              pr="sm"
              key={model.id}
            >
              <Group gap={0}>
                <Group
                  p={"sm"}
                  onClick={() => {
                    window.open(
                      generatePath(Route.ModelTab, {
                        modelId: model.id,
                        modelTabId: "historicals",
                      }),
                      "_blank",
                      "noreferrer",
                    );
                  }}
                  flex={1}
                >
                  <ThemeIcon variant="white" color="blue">
                    <BsFileEarmarkSpreadsheetFill size={32} />
                  </ThemeIcon>
                  <div>
                    <Text size="sm" fw={500}>
                      {model.name}
                    </Text>

                    <Text c="dimmed" size="xs">
                      Created on {model.createdAt.toDate().toLocaleDateString()}
                    </Text>
                  </div>
                </Group>
                {(deal?.modelIds?.length || 0) > 1 && (
                  <Tooltip label="Pin to top" withArrow>
                    <ActionIcon
                      variant="subtle"
                      color="gray"
                      component="p"
                      onClick={() => {
                        if (model.id === deal?.pinnedModelId) return;
                        setDeal(
                          {
                            pinnedModelId:
                              deal!.pinnedModelId === model.id
                                ? null
                                : model.id,
                          },
                          { merge: true },
                        );
                      }}
                    >
                      {model.id === deal?.pinnedModelId ? (
                        <RiPushpin2Fill size={22} />
                      ) : (
                        <RiPushpinLine size={22} />
                      )}
                    </ActionIcon>
                  </Tooltip>
                )}

                <Menu
                  transitionProps={{ transition: "pop" }}
                  withArrow
                  arrowPosition="center"
                  position="bottom-end"
                >
                  <Menu.Target>
                    <ActionIcon variant="subtle" color="gray" component="p">
                      <BsThreeDots size={22} />
                    </ActionIcon>
                  </Menu.Target>
                  <Menu.Dropdown>
                    <Menu.Item
                      leftSection={<LiaClone size={18} />}
                      onClick={() => {
                        clone(deal!.id, model.id);
                      }}
                    >
                      Clone
                    </Menu.Item>
                    <Menu.Item
                      leftSection={<LiaTrashAltSolid size={18} />}
                      color="red"
                      onClick={() => confirmDeleteModal(model)}
                    >
                      Delete Model
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              </Group>
            </UnstyledButton>
          ))}
        </Stack>
      </ScrollArea>
    </Stack>
  );
}
