import { auth } from "@/firebase";
import { Route } from "@/paths";
import { Model } from "@/resources/models";
import { CustomCellRendererProps } from "@ag-grid-community/react";
import { Anchor } from "@mantine/core";
import { PiGlobeSimpleBold, PiUsersThreeBold } from "react-icons/pi";

export const NamerRenderer = (props: CustomCellRendererProps) => {
  const model = props.data as Model;
  const isPublic =
    model.allowedEmails &&
    model.allowedEmails?.length > 0 &&
    model.allowedEmails?.includes("public") &&
    model.creatorId === auth.currentUser?.uid;

  const isShared =
    model.allowedEmails &&
    model.allowedEmails?.filter((email) => email !== "public").length > 0 &&
    model.creatorId === auth.currentUser?.uid;

  return (
    <>
      <Anchor
        underline="always"
        href={Route.Model.replace(":modelId", model.id)}
        inherit
      >
        {model.name}
      </Anchor>{" "}
      {isPublic && <PiGlobeSimpleBold size={16} />}{" "}
      {isShared && <PiUsersThreeBold size={16} />}
    </>
  );
};
