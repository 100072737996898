import { ResourceEntry } from "@/resources/_resource";
import { DatePickerInput } from "@mantine/dates";
import { Timestamp } from "firebase/firestore";

export const Timestamps = ({ entry }: { entry: ResourceEntry }) => {
  const [item] = entry.useItem();
  const [update] = entry.useUpdate();

  return (
    <>
      <DatePickerInput
        label="Created"
        size="sm"
        value={item?.createdAt?.toDate()}
        onChange={(date) => {
          if (!date) return;
          update({ createdAt: Timestamp.fromDate(date) });
        }}
      />
      <DatePickerInput
        label="Last Updated"
        size="sm"
        value={item?.updatedAt?.toDate() || item?.createdAt?.toDate()}
        readOnly
        styles={{
          input: {
            cursor: "not-allowed",
            background: "transparent",
          },
        }}
      />
    </>
  );
};
