import { emptyModel } from "@/financeModels/owasco/model";
import { auth, firestore } from "@/firebase";
import { useDealDirectoryEntry } from "@/resources/dealDirectories";
import { Model } from "@/resources/models";
import {
  Timestamp,
  addDoc,
  collection,
  doc,
  getDoc,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  where,
} from "firebase/firestore";
import { useMemo, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useCollection } from "react-firebase-hooks/firestore";

export const collectionRef = collection(firestore, "model");

export const create = async (model?: Partial<Model>) => {
  return await addDoc(collectionRef, {
    createdAt: serverTimestamp() as Timestamp,
    updatedAt: serverTimestamp() as Timestamp,
    creatorId: model?.creatorId || auth?.currentUser?.uid || "anonymous",
    name: "Untitled Model",
    status: "published",
    ...model,
    copyedFromEmail: null,
    copyedFromUserId: null,
    allowedEmails: [],
    excerpt: {
      targetPurchasePrice: 0,
      cashFlow: 0,
    },
  } satisfies Omit<Model, "id">);
};

export const remove = async (id: string) => {
  return await setDoc(
    doc(collectionRef, id),
    {
      status: "trashed",
    } as Partial<Model>,
    { merge: true },
  );
};

export const clone = async (id: string, data?: Partial<Model>) => {
  const modelSnap = await getDoc(doc(collectionRef, id));
  const latestRevisionSnap = await getDoc(
    doc(collectionRef, id, "revision", "latest"),
  );

  if (!modelSnap.exists()) throw new Error("Model does not exist");

  const snapshot = await addDoc(collectionRef, {
    ...modelSnap.data(),
    name: modelSnap.data().name + " (copy)",
    creatorId: auth?.currentUser?.uid,
    createdAt: serverTimestamp(),
    status: "published",
    updatedAt: serverTimestamp(),
    copyedFromUserId: id,
    copyedFromEmail: modelSnap.data().creatorId,
    allowedEmails: [],
    ...data,
  });

  const latestRevisionSnapData = latestRevisionSnap.data();

  if (latestRevisionSnapData) {
    await setDoc(
      doc(collectionRef, snapshot.id, "revision", "latest"),
      latestRevisionSnapData,
    );
  }

  return snapshot;
};

export const useModelsSharedPaginationQuery = () => {
  const [user] = useAuthState(auth);

  return useMemo(() => {
    return query(
      collectionRef,
      where("status", "==", "published"),
      where("allowedEmails", "array-contains", user?.email || ""),
      orderBy("createdAt", "desc"),
    );
  }, [user?.email]);
};

export const useModelsPaginationQuery = () => {
  const [user] = useAuthState(auth);
  return useMemo(() => {
    return query(
      collectionRef,
      where("creatorId", "==", user?.uid || ""),
      where("status", "==", "published"),
      orderBy("createdAt", "desc"),
    );
  }, [user?.uid]);
};

export const useQuery = () => {
  const tracker = useDealDirectoryEntry();

  return query(
    collectionRef,
    where("creatorId", "==", tracker?.id),
    where("status", "==", "published"),
    orderBy("createdAt", "desc"),
  );
};

export const useModels = () => {
  const modelQuery = useQuery();

  const [models, loading, error] = useCollection(modelQuery);

  return [
    (models?.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    })) || []) as Model[],
    loading,
    error,
  ] as const;
};

export const useCreateModel = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const createModel = async (
    model?: Partial<Model>,
    data?: {
      [key: string]:
        | number
        | string
        | boolean
        | { [key: string]: number | string | boolean };
    },
  ) => {
    setLoading(true);
    try {
      const m = await create(model);

      if (data !== undefined) {
        const modelRef = doc(collectionRef, m.id);
        const modelDocRef = doc(modelRef, "revision", "latest");
        await setDoc(modelDocRef, { ...emptyModel, ...data });
      }

      return m;
    } catch (error: unknown) {
      setError(error as Error);
    }
    setLoading(false);
  };

  return [createModel, loading, error] as const;
};

export const useRemoveModel = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const removeModel = async (id: string) => {
    setLoading(true);
    try {
      await remove(id);
    } catch (error: unknown) {
      setError(error as Error);
    }
    setLoading(false);
  };

  return [removeModel, loading, error] as const;
};

export const useCloneModel = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const cloneModel = async (id: string, data?: Partial<Model>) => {
    setLoading(true);
    try {
      const snapshot = await clone(id, data);
      return snapshot;
    } catch (error: unknown) {
      setError(error as Error);
    }
    setLoading(false);
  };

  return [cloneModel, loading, error] as const;
};

export const useQuerySharedWithMe = () => {
  return query(
    collectionRef,
    where("status", "==", "published"),
    where("allowedEmails", "array-contains", auth?.currentUser?.email),
    orderBy("createdAt", "desc"),
  );
};
