import { PageForgotPassword } from "@/components/PageForgotPassword/PageForgotPassword";
import { PageHome } from "@/components/PageHome/PageHome";
import { PageLogin } from "@/components/PageLogin/PageLogin";
import { PageModel } from "@/components/PageModel/PageModel";
import { PageNotFound } from "@/components/PageNotFound/PageNotFound";
import { PageProfile } from "@/components/PageProfile/PageProfile";
import { PageSignup } from "@/components/PageSignup/PageSignup";
import { Route } from "@/paths";
import { Outlet } from "react-router-dom";
import { Doc } from "./components/Doc/Doc";
import { LayoutBoilerplateText } from "./components/LayoutBoilerplateText/LayoutBoilerplateText";
import { PageApp } from "./components/PageApp/PageApp";
import { PageAuthAction } from "./components/PageAuthAction/PageAuthAction";
import { PageCheckout } from "./components/PageCheckout/PageCheckout";
import { PageCheckoutThankYou } from "./components/PageCheckoutThankYou/PageCheckoutThankYou";
import { PageContact } from "./components/PageContact/PageContact";
import { PageContactSales } from "./components/PageContactSales/PageContactSales";
import { PageContacts } from "./components/PageContacts/PageContacts";
import { PageDeal } from "./components/PageDeal/PageDeal";
import { PageDealTracker } from "./components/PageDealTracker/PageDealTracker";
import { PageError } from "./components/PageError/PageError";
import { PageListSignups } from "./components/PageListSignups/PageListSignups";
import { Main } from "./components/PageModel/Main/Main";
import { PageModels } from "./components/PageModels/PageModels";
import { PagePricing } from "./components/PagePricing/PagePricing";
import { PageTracker } from "./components/PageTracker/PageTracker";
import { PageWaitlist } from "./components/PageWaitlist/PageWaitlist";
import { RequireAuth } from "./components/RequireAuth/RequireAuth";

export const routes = [
  {
    ...(import.meta.env.PROD ? { errorElement: <PageError /> } : {}),
    element: <PageTracker />,
    children: [
      {
        path: Route.Home,
        element: <PageHome />,
      },
      {
        path: Route.Login,
        element: <PageLogin />,
      },
      {
        path: Route.Signup,
        element: <PageSignup />,
      },
      {
        path: Route.Waitlist,
        element: <PageWaitlist />,
      },
      {
        path: Route.ForgotPassword,
        element: <PageForgotPassword />,
      },
      {
        path: Route.AuthAction,
        element: <PageAuthAction />,
      },
      {
        path: Route.Pricing,
        element: <PagePricing />,
      },
      {
        path: Route.ContactSales,
        element: <PageContactSales />,
      },

      {
        path: Route.Model,
        element: <PageModel />,
        children: [
          {
            path: "",
            element: <Main />,
          },
          {
            path: ":modelTabId",
            element: <Main />,
          },
        ],
      },

      {
        element: <RequireAuth />,
        children: [
          {
            path: Route.Checkout,
            element: <PageCheckout />,
          },
          {
            path: Route.CheckoutThankYou,
            element: <PageCheckoutThankYou />,
          },
          {
            path: Route.Profile,
            element: <PageProfile />,
          },

          {
            element: <PageApp />,
            children: [
              {
                path: Route.DealTracker,
                element: <PageDealTracker />,
              },
              {
                path: Route.Models,
                element: <PageModels />,
              },
              {
                path: Route.Contacts,
                element: <PageContacts />,
              },
            ],
          },

          {
            path: Route.Deal,
            element: <PageDeal />,
          },

          {
            path: Route.Contact,
            element: <PageContact />,
          },
          {
            path: "listSignups",
            element: <PageListSignups />,
          },
        ],
      },

      // Legal routes
      {
        element: (
          <LayoutBoilerplateText>
            <Outlet />
          </LayoutBoilerplateText>
        ),
        children: [
          {
            path: Route.PrivacyPolicy,
            element: <Doc path="@/docs/pages/privacy-policy.mdx" />,
          },
          {
            path: Route.TermOfService,
            element: <Doc path="@/docs/pages/terms-of-service.mdx" />,
          },
          {
            path: Route.AboutUs,
            element: <Doc path="@/docs/pages/about-us.mdx" />,
          },
        ],
      },

      {
        path: Route.NotFound,
        element: <PageNotFound />,
      },
    ],
  },
];

export { Route };
