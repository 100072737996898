import contactsResource, { Contact } from "@/resources/contacts";
import { useDealEntry } from "@/resources/deals";
import { Button, SegmentedControl, Stack, TextInput } from "@mantine/core";
import { modals } from "@mantine/modals";
import { arrayUnion, limit, orderBy } from "firebase/firestore";
import { useEffect, useState } from "react";
import { RiUserAddFill } from "react-icons/ri";
import { stringSimilarity } from "string-similarity-js";
import { EditContactForm } from "../EditContactForm/EditContactForm";

export const openAddContactModal = () => {
  modals.open({
    title: "Add Contact",
    children: <AddContactModal />,
  });
};

const AddContactModal = () => {
  const entry = useDealEntry();
  const [value, setValue] = useState("Add New Contact");

  const onSubmitNewContact = async (contact: Partial<Contact>) => {
    const newContact = await contactsResource.create(contact);
    await entry.update({
      contactIds: arrayUnion(newContact.id) as unknown as string[],
    });
    modals.closeAll();
  };

  return (
    <Stack>
      <SegmentedControl
        color="blue"
        value={value}
        onChange={setValue}
        data={["Add New Contact", "Add Existing Contact"]}
        styles={{
          control: { borderRadius: 0, width: 160 },
        }}
      />
      {value === "Add New Contact" ? (
        <EditContactForm onSubmit={onSubmitNewContact} />
      ) : (
        <AddExistingContact />
      )}
    </Stack>
  );
};

const AddExistingContact = () => {
  const entry = useDealEntry();
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [allContacts, setAllContacts] = useState<Contact[] | null>();

  useEffect(() => {
    const fetchContacts = async () => {
      const contacts =
        (await contactsResource.getItems({
          constraints: [orderBy("createdAt", "desc"), limit(3)],
        })) || [];
      setAllContacts(contacts);
    };
    fetchContacts();
  }, []);

  useEffect(() => {
    if (loading) return;
    if (search.length < 3) return;
    setLoading(true);

    const fetchContacts = async () => {
      const contacts = (await contactsResource.getItems()) || [];
      setAllContacts(contacts);
    };
    fetchContacts();
  }, [search]);

  // filter and search contacts name and email, firm with stringSimilarity > 0.5
  // limit to 10 contacts
  const filteredContacts = allContacts
    ?.filter((contact) => {
      if (
        stringSimilarity(search, contact.name) > 0.2 ||
        stringSimilarity(search, contact.email) > 0.2 ||
        stringSimilarity(search, contact.firm) > 0.2
      )
        return true;

      if (search.length < 3) return false;

      return (
        contact?.name?.toLowerCase().includes(search.toLowerCase()) ||
        contact?.email?.toLowerCase().includes(search.toLowerCase()) ||
        contact?.firm?.toLowerCase().includes(search.toLowerCase())
      );
    })
    .slice(0, 10)
    .sort((a, b) => {
      return (
        stringSimilarity(search, a.name) - stringSimilarity(search, b.name)
      );
    });

  const onSelect = async (contact: Contact) => {
    await entry.update({
      contactIds: arrayUnion(contact.id) as unknown as string[],
    });
    modals.closeAll();
  };

  return (
    <Stack gap={"xs"} align="center">
      <TextInput
        label="Search Contact"
        value={search}
        onChange={(e) => {
          setSearch(e.currentTarget.value);
        }}
        w={"100%"}
      />

      {filteredContacts?.map((contact) => (
        <Button
          key={contact.id}
          onClick={() => onSelect(contact)}
          w={"100%"}
          variant="outline"
          leftSection={<RiUserAddFill size={20} />}
          justify="flex-start"
        >
          {contact.name || "(No name)"} {contact.email && `(${contact.email})`}{" "}
          {contact.firm && `- ${contact.firm}`}
        </Button>
      ))}
    </Stack>
  );
};
