import { auth } from "@/firebase";
import { useDealDirectoryEntry } from "@/resources/dealDirectories";
import resource, { Deal } from "@/resources/deals";
import { query, where } from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import { useParams } from "react-router-dom";

export const useModelDeal = (id: string = "unknown") => {
  const tracker = useDealDirectoryEntry();
  const { modelId = id } = useParams();

  const q =
    !modelId || !auth.currentUser?.uid
      ? null
      : query(
          resource.collectionRef,
          where("modelIds", "array-contains", modelId),
          where("creatorId", "==", tracker?.id || "UNKNOWN"),
          where("status", "==", "published"),
        );

  const [snapshot, loading, error] = useCollection(q);

  const deal =
    snapshot && snapshot.docs.length > 0
      ? ({ ...snapshot?.docs[0]?.data(), id: snapshot?.docs[0]?.id } as Deal)
      : null;

  return [deal, loading, error] as const;
};
