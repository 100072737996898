import { auth } from "@/firebase";
import { BaseResource, buildResource } from "@/resources/_resource";
import { Tag } from "./tags";

export type ContactDirectory = BaseResource & {
  jobTitleTags: Tag[];
};

const resource = buildResource<ContactDirectory>({
  collectionPath: "contactDirectory",
  name: "Contact Directory",
  getDefaultItem: () => ({
    jobTitleTags: [
      { id: "broker", label: "Broker", paletteId: "sunnyYellow" },
      { id: "investor", label: "Investor", paletteId: "lavender" },
      { id: "lender", label: "Lender", paletteId: "royalBlue" },
      { id: "qoe", label: "QoE", paletteId: "forestGreen" },
      { id: "attorney", label: "Attorney", paletteId: "mango" },
    ],
  }),
});

export default resource;

export const useContactDirectoryEntry = () => {
  const entry = resource.entry(auth.currentUser!.uid);
  return entry;
};

export const getContactDirectoryEntry = () => {
  const entry = resource.entry(auth.currentUser!.uid);
  return entry;
};

export const useContactDirectoryJobTitleTags = () => {
  const entry = useContactDirectoryEntry();
  const [item, loading, error] = entry.useItem();
  return [item?.jobTitleTags, loading, error] as const;
};
