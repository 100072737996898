import { manifest } from "@/docs/manifest";
import {
  businessAcquisitionCostAtom,
  equityDollarAtom,
  equityInvestorDollarAtom,
  isEquityInvestorsIncludedAtom,
  isRealEstateIncludedAtom,
  isRollinDealExpensesAtom,
  isRollinLoanTransactionFeeAtom,
  isRollinSBAGuarantyFeeAtom,
  loanTransactionFeeAtom,
  realEstateDollarValueAtom,
  sbaGuarantyFeeAtom,
  sbaLoanDollarAtom,
  sbaLoanTotalWithOptionalFeesAtom,
  sellersNoteDollarAtom,
  targetPurchasePriceAtom,
  totalDealExpensesAtom,
} from "@/financeModels/owasco/general";
import { MathResult } from "@/financeModels/types";
import { atom } from "jotai";

type Item = [string, MathResult, keyof typeof manifest];

export const usesAtom = atom((get) => {
  const targetPurchasePrice = get(targetPurchasePriceAtom);
  const dealExpenses = get(totalDealExpensesAtom);
  const loanTransactionFee = get(loanTransactionFeeAtom);
  const sbaguarantyFee = get(sbaGuarantyFeeAtom);
  const isRealEstateIncluded = get(isRealEstateIncludedAtom);
  const realEstateDollarValue = get(realEstateDollarValueAtom);
  const isRollinDealExpenses = get(isRollinDealExpensesAtom);
  const businessAcquisitionCost = get(businessAcquisitionCostAtom);
  const hasSBAAmount = Number(get(sbaLoanDollarAtom)) > 0;

  const items: Item[] = [
    ...(isRealEstateIncluded
      ? [
          [
            "Business Acquisition",
            businessAcquisitionCost,
            "@/docs/pages/model/overview/uses-acquisition-cost.mdx",
          ],
          [
            "Real Estate Transaction",
            realEstateDollarValue,
            "@/docs/pages/model/overview/uses-real-estate-transaction.mdx",
          ],
        ]
      : [
          [
            "Business Acquisition",
            targetPurchasePrice,
            "@/docs/pages/model/overview/uses-real-estate-transaction.mdx",
          ],
        ]),

    ...(isRollinDealExpenses
      ? [
          [
            "Deal Expenses",
            dealExpenses,
            "@/docs/pages/model/overview/uses-real-estate-transaction.mdx",
          ] as Item,
        ]
      : []),

    ...(hasSBAAmount
      ? [
          [
            "Loan Transaction Fee",
            loanTransactionFee,
            "@/docs/pages/model/overview/uses-loan-transaction-fee.mdx",
          ],
        ]
      : []),
    ...(hasSBAAmount
      ? [
          [
            "SBA Guaranty Fee",
            sbaguarantyFee,
            "@/docs/pages/model/overview/uses-sba-guaranty-fee.mdx",
          ],
        ]
      : []),
  ] as Item[];

  return items;
});

export const usesTotalCostAtom = atom((get) => {
  const items = get(usesAtom);
  return items.reduce((acc, [, value]) => acc + Number(value), 0);
});

export const sourcesAtom = atom((get) => {
  const sbaLoanDebt = get(sbaLoanTotalWithOptionalFeesAtom);
  const sellersNote = get(sellersNoteDollarAtom);
  const isEquityInvestorsIncluded = get(isEquityInvestorsIncludedAtom);
  const equityDollar = get(equityDollarAtom);
  const equityInvestorDollar = get(equityInvestorDollarAtom);
  const isRollinLoanTransactionFee = get(isRollinLoanTransactionFeeAtom);
  const isRollinSBAGuarantyFee = get(isRollinSBAGuarantyFeeAtom);
  const loanTransactionFee = get(loanTransactionFeeAtom);
  const sbaguarantyFee = get(sbaGuarantyFeeAtom);
  const hasSBAAmount = Number(get(sbaLoanDollarAtom)) > 0;

  const items: Item[] = [
    [
      "SBA 7(a) Loan",
      sbaLoanDebt,
      "@/docs/pages/model/overview/sources-sb7a-loan.mdx",
    ],
    [
      "Seller's Note",
      sellersNote,
      "@/docs/pages/model/overview/sources-sellers-note.mdx",
    ],
  ];

  if (!isEquityInvestorsIncluded) {
    items.push([
      "Equity",
      equityDollar,
      "@/docs/pages/model/overview/sources-equity.mdx",
    ]);
  } else {
    items.push([
      "Equity - Searcher",
      equityDollar,
      "@/docs/pages/model/overview/sources-equity-searcher.mdx",
    ]);
    items.push([
      "Equity - Investor",
      equityInvestorDollar,
      "@/docs/pages/model/overview/sources-equity-investor.mdx",
    ]);
  }

  if (!isRollinLoanTransactionFee && hasSBAAmount)
    items.push([
      "Loan Transaction Fee",
      loanTransactionFee,
      "@/docs/pages/model/overview/sources-loan-transaction-fee.mdx",
    ]);

  if (!isRollinSBAGuarantyFee && hasSBAAmount)
    items.push([
      "SBA Guaranty Fee",
      sbaguarantyFee,
      "@/docs/pages/model/overview/sources-sba-guaranty-fee.mdx",
    ]);

  return items;
});

export const sourcesTotalCostAtom = atom((get) => {
  const items = get(sourcesAtom);
  return items.reduce((acc, [, value]) => acc + Number(value), 0);
});
