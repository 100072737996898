import { useAuthContext } from "@/contexts/AuthProvider";
import { useIsModelReadOnly } from "@/hooks/useModel";
import { Route } from "@/paths";
import { Button } from "@mantine/core";
import { BsFillPencilFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { css } from "vite-plugin-inline-css-modules";

const styles = css`
  @keyframes jump-shaking {
    0%,
    88% {
      transform: translateX(0);
    }
    90% {
      transform: translateY(-9px);
    }
    92% {
      transform: translateY(-9px) rotate(7deg);
    }
    94% {
      transform: translateY(-9px) rotate(-7deg);
    }
    96% {
      transform: translateY(-9px) rotate(7deg);
    }
    98% {
      transform: translateY(-9px) rotate(-7deg);
    }
    100% {
      transform: translateY(0) rotate(0);
    }
  }

  .shake {
    animation: jump-shaking 4s infinite;
  }
`;

export const SignupDazzler = () => {
  const { user } = useAuthContext();
  const [readOnly] = useIsModelReadOnly();

  if (!readOnly || user) return null;

  return (
    <Button
      pos={"absolute"}
      bottom={12}
      right={12}
      p={"md"}
      className={styles.shake}
      leftSection={<BsFillPencilFill />}
      size="xl"
      component={Link}
      to={Route.Signup}
      state={{ returnTo: window.location.pathname }}
    >
      Edit This Modal
    </Button>
  );
};
