import { isModelReadOnlyFromHookAtom } from "@/hooks/useModel";
import { ActionIcon, Box, BoxComponentProps, Tooltip } from "@mantine/core";
import { useAtomValue } from "jotai";
import { ReactNode, useEffect, useState } from "react";
import { FaUndoAlt } from "react-icons/fa";
import { RiQuestionLine } from "react-icons/ri";
import { selectActiveElement } from "../hooks/useTabCoordinates";
import classes from "./Cell.module.css";
import { defaultWidth } from "./Wrapper";

const arrowKeys = ["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"];

export const Stub = ({
  enableEditing,
  label,
  moveFocus,
  resetCell,
  ta,
  locked,
  overrided,
  animationKey,
  bg = "white",
  blank,
  startEditRegex = /\d/,
  justifyContent = "right",
  tooltip,
}: BoxComponentProps & {
  label: ReactNode;
  resetCell?: () => void;
  enableEditing: (value?: null | number) => void;
  moveFocus: (dir: "up" | "down" | "left" | "right") => void;
  ta?: "left" | "right" | "center";
  locked?: boolean;
  overrided?: boolean;
  blank?: boolean;
  animationKey?: string;
  startEditRegex?: RegExp;
  justifyContent?: "left" | "right" | "center";
  tooltip?: string;
}) => {
  const [showInfoTooltip, setShowInfoTooltip] = useState(false);
  const [animationKeyCount, setAnimationKeyCount] = useState<number>(-3);

  const isModelReadOnly = useAtomValue(isModelReadOnlyFromHookAtom);

  if (overrided) bg = `var(--mantine-color-accent-0) `;
  if (locked)
    bg =
      "linear-gradient(45deg, #f5f5f5 25%, #f9f9f9 25%, #f9f9f9 50%, #f5f5f5 50%, #f5f5f5 75%, #f9f9f9 75%, #f9f9f9 100%) 0 0 / 20px 20px";
  if (blank) bg = "#EBEBEE";

  if (isModelReadOnly && !locked) bg = "var(--mantine-color-gray-0)";
  if (isModelReadOnly) locked = true;

  let lockedMessageText = "Value is calculated. See row label for more info.";
  if (isModelReadOnly) lockedMessageText = "This model is read-only.";

  useEffect(() => {
    if (animationKey === undefined) return;
    setAnimationKeyCount((c) => (c >= 1 ? 0 : c + 1));
  }, [animationKey, setAnimationKeyCount]);

  return (
    <div
      tabIndex={0}
      style={{
        //...props.style,
        height: "100%",
        width: "100%",
        position: "relative",
        userSelect: "none",
        overflow: "hidden",
        textAlign: ta,
        display: "flex",
        justifyContent,
        alignItems: "center",
        background: bg as string,
        padding: "0 8px",
        //...(blank ? { boxShadow: "inset 0 0 0 1px #DEE2E6" } : {}),
      }}
      className={[
        "stub-cell",
        `flash-${animationKeyCount}`,
        classes.cell,
        locked ? classes.locked : "",
      ].join(" ")}
      onDoubleClick={() => {
        if (locked) {
          setShowInfoTooltip(true);
          setTimeout(() => setShowInfoTooltip(false), 2000);
          return;
        }

        enableEditing();
        selectActiveElement();
      }}
      onKeyDown={(e) => {
        if (arrowKeys.includes(e.key)) {
          e.preventDefault();
          moveFocus(
            e.key.replace("Arrow", "").toLowerCase() as
              | "up"
              | "down"
              | "left"
              | "right",
          );
          return;
        }

        if (locked) {
          setShowInfoTooltip(true);
          setTimeout(() => setShowInfoTooltip(false), 2000);
          return;
        }

        if (e.key === "Enter") {
          enableEditing();
          selectActiveElement();
        }

        if (e.key.match(startEditRegex)) {
          enableEditing(Number(e.key));
        }

        if (e.key === "Delete") {
          resetCell?.();
        }

        if (e.key === "Backspace") {
          enableEditing(null);
        }
      }}
    >
      {locked && showInfoTooltip && (
        <Tooltip
          label={lockedMessageText}
          withArrow
          opened
          position="top"
          arrowSize={8}
          offset={{ mainAxis: 10 }}
        >
          <Box pos={"absolute"} w={"100%"} h={"100%"}></Box>
        </Tooltip>
      )}

      {overrided && (
        <Tooltip
          label="Reset to the auto-calculated value based on 'Growth Rate (%)'"
          withArrow
        >
          <ActionIcon
            size={"xs"}
            variant="light"
            color="accent"
            onClick={resetCell}
            pos={"absolute"}
            left={2}
            radius={99}
          >
            <FaUndoAlt size={11} />
          </ActionIcon>
        </Tooltip>
      )}

      {tooltip ? (
        <Tooltip
          label={tooltip}
          withArrow
          w={defaultWidth}
          multiline
          ta={"center"}
        >
          <div style={{ position: "relative" }}>
            <span
              style={{
                borderBottom: "1px dotted var(--mantine-color-accent-8)",
              }}
            >
              {label}
            </span>
            <RiQuestionLine
              style={{
                position: "absolute",
                top: 3,
                left: "calc(100% + 4px)",
              }}
              size={16}
            />
          </div>
        </Tooltip>
      ) : (
        label
      )}
    </div>
  );
};
