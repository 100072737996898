import { useIsPlanInactive } from "@/hooks/useIsPlanInactive";
import { Route } from "@/paths";
import { Anchor, Box, Group } from "@mantine/core";
import { useMemo } from "react";
import { MdOutlineArrowForward } from "react-icons/md";
import { Link } from "react-router-dom";

export const LayoutTrialEndWarning = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { isPlanInactive } = useIsPlanInactive();
  const childrenMemo = useMemo(() => children, [children]);

  const alertText =
    "Your plan has expired. You are in read-only mode. See our pricing page to continue your subscription.";

  return (
    <Box
      w={"100dvw"}
      h={"100dvh"}
      style={{
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {isPlanInactive && (
        <Group
          gap={4}
          w={"100dvw"}
          h={40}
          bg={"pink.2"}
          justify={"center"}
          align={"center"}
        >
          <Anchor underline="always" component={Link} to={Route.Pricing} inline>
            {alertText}
          </Anchor>
          <MdOutlineArrowForward />
        </Group>
      )}
      <Box
        w={"100dvw"}
        h={`calc(100dvh - ${isPlanInactive ? 40 : 0}px)`}
        style={{ overflow: "hidden" }}
      >
        {childrenMemo}
      </Box>
    </Box>
  );
};
