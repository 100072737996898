import { getPaletteById, Tag } from "@/resources/tags";
import { Badge, BadgeProps } from "@mantine/core";

export const TagsRenderer = ({
  tagOptions,
  tagIds,
  ...props
}: BadgeProps & {
  tagOptions: Tag[] | undefined;
  tagIds: string[] | undefined;
}) => {
  if (!tagIds || !tagOptions) return null;

  return (
    <>
      {tagIds.map((tagId: string) => {
        const tag = tagOptions?.find((tag) => tag.id === tagId);
        const color = getPaletteById(tag?.paletteId || "");
        return (
          <Badge
            display={!tag ? "none" : undefined}
            key={tagId}
            size="lg"
            color={color?.backgroundColor}
            variant="filled"
            c={color?.fontColor}
            tt={"none"}
            mr={4}
            {...props}
          >
            {tag?.label}
          </Badge>
        );
      })}
    </>
  );
};
