import {
  sbaLoanAmortizationPaymentsAtom,
  sbaLoanAmortizationScheduleAtom,
  sbaLoanNumberOfPaymentsPerYearAtom,
  sbaLoanRecurringPaymentAtom,
  sellersNoteAmortizationPaymentsAtom,
  sellersNoteNumberOfPaymentsPerYearAtom,
  sellersNoteRecurringPaymentAtom,
} from "@/financeModels/owasco/amortization";
import {
  sbaLoanInterestRateAtom,
  sbaLoanStartDateAtom,
  sbaLoanTotalWithOptionalFeesAtom,
  sellersNoteAmortizationScheduleAtom,
  sellersNoteDollarAtom,
  sellersNoteInterestRateAtom,
  sellersNoteStartDateAtom,
} from "@/financeModels/owasco/general";
import {
  sba7aPaymentsMetaFocusAtom,
  sellersNotePaymentsMetaFocusAtom,
} from "@/financeModels/owasco/paymentsMeta";
import { PaymentMeta } from "@/financeModels/owasco/types";
import { toNumberOrNull } from "@/utils/math";
import { Group, SegmentedControl, Stack, Text } from "@mantine/core";
import { useLocalStorage } from "@mantine/hooks";
import { PrimitiveAtom, useAtomValue } from "jotai";
import { BsPostcardFill } from "react-icons/bs";
import { RiBankFill } from "react-icons/ri";
import { AmortizationTable } from "./AmortizationTable";
import { LoanTable } from "./LoanTable";

export const PanelAmortization = () => {
  const [isSbaLoan, setIsSbaLoan] = useLocalStorage({
    key: "amortPanelIsSbaLoan",
    defaultValue: true,
  });

  const loanAmount = useAtomValue(
    isSbaLoan ? sbaLoanTotalWithOptionalFeesAtom : sellersNoteDollarAtom,
  );
  const interestRate = useAtomValue(
    isSbaLoan ? sbaLoanInterestRateAtom : sellersNoteInterestRateAtom,
  );
  const paymentsPerYear = useAtomValue(
    isSbaLoan
      ? sbaLoanNumberOfPaymentsPerYearAtom
      : sellersNoteNumberOfPaymentsPerYearAtom,
  );
  const payments = useAtomValue(
    isSbaLoan
      ? sbaLoanAmortizationPaymentsAtom
      : sellersNoteAmortizationPaymentsAtom,
  );

  const loanStartDate = useAtomValue(
    isSbaLoan ? sbaLoanStartDateAtom : sellersNoteStartDateAtom,
  );
  const schedule = useAtomValue(
    isSbaLoan
      ? sbaLoanAmortizationScheduleAtom
      : sellersNoteAmortizationScheduleAtom,
  );

  const paymentsMetaAtom = isSbaLoan
    ? sba7aPaymentsMetaFocusAtom
    : sellersNotePaymentsMetaFocusAtom;

  const recurringPaymentAtom = isSbaLoan
    ? sbaLoanRecurringPaymentAtom
    : sellersNoteRecurringPaymentAtom;

  return (
    <Stack flex={1} h="100%">
      <Group justify={"center"} align={"center"}>
        <Stack gap={"xs"} mr={"xl"}>
          <Text fw={500} size="xl">
            Loan Amortization Schedule
          </Text>
          <SegmentedControl
            color="blue"
            w={270}
            value={isSbaLoan ? "sba7a" : "sellersNote"}
            onChange={(value) => setIsSbaLoan(value === "sba7a")}
            data={[
              {
                label: (
                  <>
                    <RiBankFill
                      style={{ marginRight: 5, position: "relative", top: 1 }}
                    />
                    SBA 7(a) Loan
                  </>
                ),
                value: "sba7a",
              },
              {
                label: (
                  <>
                    <BsPostcardFill
                      style={{ marginRight: 5, position: "relative", top: 2 }}
                    />
                    Seller's Note
                  </>
                ),
                value: "sellersNote",
              },
            ]}
          />
        </Stack>
        <LoanTable
          payments={payments}
          loanAmount={toNumberOrNull(loanAmount) || 0}
          interestRate={toNumberOrNull(interestRate) || 0}
          paymentsPerYear={paymentsPerYear}
          schedule={toNumberOrNull(schedule) || 0}
          loanStartDate={loanStartDate.toDate()}
          docPrefix={isSbaLoan ? "sb7a" : "sellers"}
        />
      </Group>
      <Stack flex={1} h="100%">
        <AmortizationTable
          payments={payments}
          paymentsMetaAtom={
            paymentsMetaAtom as PrimitiveAtom<Record<number, PaymentMeta>>
          }
          recurringPaymentAtom={recurringPaymentAtom}
          docPrefix={isSbaLoan ? "sb7a" : "sellers"}
        />
      </Stack>
    </Stack>
  );
};
