import { useSubscription } from "@/hooks/useSubscriptions";
import { Route } from "@/paths";
import {
  Button,
  Card,
  Center,
  Container,
  Group,
  Stack,
  Text,
} from "@mantine/core";
import { LuPartyPopper } from "react-icons/lu";
import { MdOutlineArrowForward } from "react-icons/md";
import { Link } from "react-router-dom";
import { LayoutPage } from "../LayoutPage/LayoutPage";

export const PageCheckoutThankYou = () => {
  const [subcription] = useSubscription();

  // stripe created date to local date
  const createdDateStr = new Date(
    (subcription?.created || 1) * 1000,
  ).toLocaleDateString();

  const billingPeriodEndStr = new Date(
    (subcription?.current_period_end || 1) * 1000,
  ).toLocaleDateString();

  return (
    //background corners are gray using css grandient, top left and bottom right
    <LayoutPage>
      <Container pt={"10dvh"} size={"lg"}>
        <Center>
          <Group justify="flex-start" align="flex-start" pos={"relative"}>
            <Stack>
              <Group align="center" justify="center">
                <LuPartyPopper size={80} />
              </Group>
              <Text size="xl" fz={30} lh={1}>
                Thank you for your purchase
              </Text>

              <Text size="lg" lh={1}>
                Your subscription is active and will renew on the billing date.
              </Text>
              <Card withBorder radius={0}>
                <Stack>
                  <Text size="xl" fz={20}>
                    Solo Plan
                  </Text>
                  <Group justify="space-between">
                    <Text lh={1}>
                      ID: <strong>{subcription?.id.replace("sub_", "")}</strong>
                    </Text>
                    <Text lh={1}>
                      Created Date: <strong>{createdDateStr}</strong>
                    </Text>
                  </Group>
                  <Group justify="space-between">
                    <Text lh={1}>
                      Status:<strong> Active</strong>
                    </Text>
                    <Text lh={1}>
                      Billing Date: <strong>{billingPeriodEndStr}</strong>
                    </Text>
                  </Group>
                </Stack>{" "}
              </Card>
              <Text size="lg" lh="1">
                You will receive an email confirmation shortly.
              </Text>
              <Group align="center">
                <Button
                  mt={"sm"}
                  component={Link}
                  to={Route.DealTracker}
                  size="lg"
                  rightSection={<MdOutlineArrowForward size={25} />}
                >
                  Continue
                </Button>
              </Group>
            </Stack>
          </Group>
        </Center>
      </Container>
    </LayoutPage>
  );
};
