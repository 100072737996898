import { Text, Tooltip } from "@mantine/core";
import { ReactNode } from "react";
import { RiQuestionFill } from "react-icons/ri";

export const QuestionTooltip = ({
  label,
  children,
  removeQuestionMarkWidth,
}: {
  label: ReactNode;
  children: React.ReactNode;
  removeQuestionMarkWidth?: boolean;
}) => {
  return (
    <Tooltip
      label={label}
      events={{ hover: true, focus: true, touch: true }}
      withArrow
    >
      <Text
        component="span"
        inherit
        style={
          {
            // cursor: "help",
            // textDecoration: "underline",
            // textDecorationStyle: "dotted",
            // verticalAlign: "top",
            // textDecorationThickness: 2,
            // textDecorationColor: "#00000033",
          }
        }
      >
        {children}
        <span
          style={
            removeQuestionMarkWidth ? { display: "inline-block", width: 0 } : {}
          }
        >
          <RiQuestionFill />
        </span>
      </Text>
    </Tooltip>
  );
};
