import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import {
  CellValueChangedEvent,
  ColDef,
  GridApi,
  GridReadyEvent,
  GridState,
  ModuleRegistry,
  RowClassParams,
  StateUpdatedEvent,
} from "@ag-grid-community/core";
import { AgGridReact, AgGridReactProps } from "@ag-grid-community/react";
import { Box, Group, Skeleton, Stack } from "@mantine/core";
import { DocumentData, Query } from "firebase/firestore";
import { atom, useSetAtom } from "jotai";
import { CSSProperties, useCallback, useMemo, useRef, useState } from "react";
import { css } from "vite-plugin-inline-css-modules";
import { useKeepGridInSyncWithQuery } from "./hooks";

ModuleRegistry.registerModules([ClientSideRowModelModule]);

export const appGridApiAtom = atom<GridApi | null>(null);
export const appGridStateAtom = atom<GridState | null>(null);
export const appRowDataUpdatedAtAtom = atom<number>(0);

export const AppGrid = ({
  id,
  query,
  columnDefs,
  onCellValueChanged,
  getRowStyle,
  isReadOnly,
  defaultColDef,
  localeText,
  initialRowData,
  blockStateSaving,
  ...props
}: AgGridReactProps & {
  id: string;
  query: Query<DocumentData, DocumentData>;
  columnDefs: ColDef[];
  defaultColDef?: ColDef;
  onCellValueChanged?: (event: CellValueChangedEvent) => void;
  getRowStyle?: (params: RowClassParams) => CSSProperties;
  isReadOnly?: boolean;
  localeText?: Record<string, string>;
  initialRowData?: DocumentData[];
  blockStateSaving?: boolean;
}) => {
  const localStorageKey = `gridState-v2-${id}`;
  const ref = useRef<AgGridReact | null>(null);
  const isGridReady = useRef(false);
  const setAppGridApiAtom = useSetAtom(appGridApiAtom);
  const setAppGridStateAtom = useSetAtom(appGridStateAtom);
  const setRowDataUpdatedAt = useSetAtom(appRowDataUpdatedAtAtom);
  const [rowData] = useState(initialRowData || []);

  const { loading } = useKeepGridInSyncWithQuery(query);

  const onGridReady = useCallback((params: GridReadyEvent): void => {
    isGridReady.current = true;
    setAppGridApiAtom(params.api);
  }, []);

  const onStateUpdated = useCallback((params: StateUpdatedEvent) => {
    if (!isGridReady.current) return;

    const state = params.api.getState();
    localStorage.setItem(localStorageKey, JSON.stringify(state));
    setAppGridStateAtom(state);
  }, []);

  const initialState = useMemo(() => {
    if (blockStateSaving) return {} as GridState;
    if (!localStorage.getItem(localStorageKey)) return {};

    const state = JSON.parse(
      localStorage.getItem(localStorageKey) || "null",
    ) as GridState;

    return {
      ...state,
      filter: {
        filterModel: {},
      },
      // sort: {
      //   sortModel: [],
      // },
    };
  }, []);

  const onCellValueChangedHandler = useCallback(
    (event: CellValueChangedEvent) => {
      onCellValueChanged?.(event);
      event.api.refreshCells({ force: true });
      event.api.onSortChanged();
    },
    [],
  );

  const getRowStyleHandler = useCallback(
    (params: RowClassParams) => {
      return {
        ...(getRowStyle ? getRowStyle(params) : {}),
        ...(isReadOnly ? readOnlyStyle : {}),
      };
    },
    [isReadOnly],
  );

  const defaultColDefWithReadOnly = useMemo(
    () => ({
      ...defaultColDef,
      ...(isReadOnly ? readOnlyColumnDef : {}),
    }),
    [isReadOnly],
  );

  const onRowDataUpdated = useCallback(() => {
    setRowDataUpdatedAt(Date.now());
  }, []);

  return (
    <AgGridReact
      className={`ag-theme-quartz ${styles.root}`}
      ref={ref}
      rowData={rowData}
      onGridReady={onGridReady}
      columnDefs={columnDefs}
      onStateUpdated={onStateUpdated}
      onCellValueChanged={onCellValueChangedHandler}
      onRowDataUpdated={onRowDataUpdated}
      getRowStyle={getRowStyleHandler}
      initialState={initialState}
      defaultColDef={defaultColDefWithReadOnly}
      getRowId={(params) => params.data.id}
      localeText={localeText}
      suppressDragLeaveHidesColumns={false}
      tooltipShowDelay={0}
      suppressServerSideFullWidthLoadingRow={true}
      suppressMultiSort={true}
      loading={loading}
      //overlayNoRowsTemplate={"⠀"}
      loadingOverlayComponent={() => (
        <Box
          pos={"absolute"}
          left={0}
          top={0}
          w={"100%"}
          h={"100%"}
          p={4}
          bg={"transparent"}
          pt={64}
        >
          <Stack gap={"xl"} px="xl">
            {Array.from({ length: 4 }).map((_, index) => (
              <Group key={index}>
                <Skeleton height={8} radius="xl" w={220} />
                <Skeleton height={8} radius="xl" w={140} />
                <Skeleton height={8} radius="xl" w={140} />
                <Skeleton height={8} radius="xl" w={140} />
              </Group>
            ))}
          </Stack>
        </Box>
      )}
      {...props}
    />
  );
};

const readOnlyColumnDef = {
  editable: false,
  cellClass: "read-only-cell",
} as const;

const readOnlyStyle = {
  backgroundColor: "#f9f9f9",
  color: "#999",
};

const styles = css`
  .root {
    --ag-wrapper-border-radius: 0 !important;
    --ag-font-family: var(--mantine-font-family);

    :global {
      .checkbox-cell-header {
        padding: 0 0 0 6px;
        font-size: 10px;
        text-align: center;

        &:after {
          content: "";
          position: absolute;
          z-index: 1;
          display: var(--ag-header-column-resize-handle-display);
          width: var(--ag-header-column-resize-handle-width);
          height: var(--ag-header-column-resize-handle-height);
          top: calc(50% - var(--ag-header-column-resize-handle-height) * 0.5);
          left: calc(100% - var(--ag-header-column-resize-handle-width));
          background-color: var(--ag-header-column-resize-handle-color);
        }

        .ag-header-cell-label {
          overflow: visible;
          text-overflow: initial;
        }
        .ag-sort-indicator-icon {
          padding-left: 0;
        }
        .ag-header-cell-text {
          width: 25px;
          text-align: center;
        }
      }
    }
  }
`;
